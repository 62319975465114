import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { memo } from 'react';
import { CardResourceType } from '../../../constants/ResourceSupport';

interface ResourceIconProps {
  type: CardResourceType;
}

const ResourceIcon = ({ type }: ResourceIconProps) => {
  switch (type) {
    case 'resource':
      return <OpenInNewIcon />;
    case 'video':
      return <OndemandVideoOutlinedIcon />;
    case 'file':
      return <TextSnippetOutlinedIcon />;
    default:
      return <OpenInNewIcon />;
  }
};

export default memo(ResourceIcon);
