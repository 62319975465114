import {
  AccessTime,
  Call,
  DesktopWindowsOutlined,
  InfoOutlined,
  LocalHospitalOutlined,
  MyLocation,
  PersonalVideo,
  Public,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import StyledIcon from '../../../Icons/IconWrapper';

export type ClinicListSchemaType = {
  icon: JSX.Element;
  content:
    | { type: 'text'; details: 'description' | 'hours' | 'walkInHours' }
    | { type: 'tel'; details: 'telephone' }
    | { type: 'link'; details: 'website' | 'dfdLink' }
    | { type: 'location'; details: 'address' };
};

export const DETAIL_SCHEMAS: {
  [Key in ClinicListSchemaType['content']['details']]: ClinicListSchemaType;
} = {
  description: {
    icon: (
      <StyledIcon
        icon={InfoOutlined}
        sx={{
          fontSize: '1em',
          alignSelf: 'center',
        }}
      />
    ),
    content: {
      type: 'text',
      details: 'description',
    },
  },
  hours: {
    icon: (
      <Box display="flex" alignItems="center" component="span">
        <StyledIcon
          icon={DesktopWindowsOutlined}
          sx={{
            fontSize: '1em',
          }}
        />
        <StyledIcon
          icon={AccessTime}
          sx={{
            fontSize: '1em',
          }}
        />
      </Box>
    ),
    content: {
      type: 'text',
      details: 'hours',
    },
  },
  walkInHours: {
    icon: (
      <Box display="flex" alignItems="center" component="span">
        <StyledIcon
          icon={LocalHospitalOutlined}
          sx={{
            fontSize: '1em',
          }}
        />
        <StyledIcon
          icon={AccessTime}
          sx={{
            fontSize: '1em',
          }}
        />
      </Box>
    ),
    content: {
      type: 'text',
      details: 'walkInHours',
    },
  },
  telephone: {
    icon: (
      <StyledIcon
        icon={Call}
        sx={{
          fontSize: '1em',
          alignSelf: 'center',
        }}
      />
    ),
    content: {
      type: 'tel',
      details: 'telephone',
    },
  },
  website: {
    icon: (
      <StyledIcon
        icon={Public}
        sx={{
          fontSize: '1em',
          alignSelf: 'center',
        }}
      />
    ),
    content: {
      type: 'link',
      details: 'website',
    },
  },
  dfdLink: {
    icon: (
      <StyledIcon
        icon={PersonalVideo}
        sx={{
          fontSize: '1em',
          alignSelf: 'center',
        }}
      />
    ),
    content: {
      type: 'link',
      details: 'dfdLink',
    },
  },
  address: {
    icon: (
      <StyledIcon
        icon={MyLocation}
        sx={{
          fontSize: '1em',
          alignSelf: 'center',
        }}
      />
    ),
    content: {
      type: 'location',
      details: 'address',
    },
  },
};
