// Modified from Honey/js/generateCheckInHours.js

import _ from 'lodash';
import { IDirtyText } from '../contexts/DataContext';
import { TimeString, WeeklyHours } from './data';

type Weekdays = readonly [
  string,
  string,
  string,
  string,
  string,
  string,
  string
];

function formatSchedule(
  openHours: WeeklyHours,
  breakHours: WeeklyHours,
  days: Weekdays,
  timeFmt: (x: TimeString) => string
) {
  const combinedDates = [];
  const formatHours = (open: TimeString, close: TimeString) =>
    `${timeFmt(open)} - ${timeFmt(close)}`;

  for (const [dayNumber, day, openHour, breakHour] of _.zip(
    _.range(0, 7),
    days,
    openHours,
    breakHours
  )) {
    const o = {
      label: day!,
      dayNumber: dayNumber!,
      openingData: openHour!,
      breakData: breakHour!,
    };

    const d = combinedDates[combinedDates.length - 1];

    if (
      d &&
      d.openingData.open === o.openingData.open &&
      d.openingData.openHour === o.openingData.openHour &&
      d.openingData.closeHour === o.openingData.closeHour &&
      ((d.breakData.open === false && d.breakData.open === o.breakData.open) ||
        (d.breakData.open === o.breakData.open &&
          d.breakData.openHour === o.breakData.openHour &&
          d.breakData.closeHour === o.breakData.closeHour))
    ) {
      let label = combinedDates[combinedDates.length - 1].label;
      if (label.indexOf('-') !== -1) {
        label = label.slice(0, label.indexOf(' - '));
      }
      combinedDates[combinedDates.length - 1].label = label + ' - ' + o.label;
    } else {
      combinedDates.push(o);
    }
  }

  return `${_.join(
    _.compact(
      _.map(combinedDates, (d) => {
        const { openingData, breakData, label } = d;

        if (!openingData.open) {
          return;
        }

        const { openHour: oH, closeHour: cH } = openingData;
        const { openHour: oHB, closeHour: cHB } = breakData;

        return breakData.open
          ? `${label}: ${formatHours(oH, oHB)} & ${formatHours(cHB, cH)}.\n`
          : `${label}: ${formatHours(oH, cH)}.\n`;
      })
    ),
    ''
  )}`;
}

function formatTo12h(time: string) {
  let timeMatch: string[] = time.match(
    /^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/
  ) || [time];

  if (timeMatch.length > 1) {
    timeMatch = timeMatch.slice(1);
    timeMatch[5] = +timeMatch[0] < 12 ? 'AM' : 'PM';
    timeMatch[0] = `${+timeMatch[0] % 12 || 12}`;
  }
  return timeMatch.join('');
}

let formatFr = <T>(t: T) => t;

const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'] as const;
const daysFr = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'] as const;

export function generateCheckinHours(
  openHours: WeeklyHours,
  breakHours: WeeklyHours
): IDirtyText {
  return {
    en: formatSchedule(openHours, breakHours, days, formatTo12h),
    fr: formatSchedule(openHours, breakHours, daysFr, formatFr),
  };
}
