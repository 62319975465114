import { useCurrentLanguage } from 'hive-react-utils';
import _ from 'lodash';
import { useCallback } from 'react';
import { VirtualClinic } from '../utils/data';

type SelfOrRecursiveArray<T> = T | Array<SelfOrRecursiveArray<T>>;

type ParamObject = {
  [value: string | number]: SelfOrRecursiveArray<
    number | string | boolean | undefined | null
  >;
};

export const DEFAULT_DFD_PARAMS: ParamObject = {
  referrer: 'ofd',
  check_in: true,
};

const useDFDLinkFactory = () => {
  const language = useCurrentLanguage();

  return useCallback(
    (clinic: VirtualClinic, otherParams?: ParamObject) => {
      const params = new URLSearchParams();

      _.each(DEFAULT_DFD_PARAMS, (value, key) => params.set(key, `${value}`));
      params.set('lang', language);
      _.each(otherParams, (value, key) => params.set(key, `${value}`));

      return clinic.dfdLink + '?' + params.toString();
    },
    [language]
  );
};

export default useDFDLinkFactory;
