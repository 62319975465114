import { Box, Button, Divider, Stack, Typography, styled } from '@mui/material';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTE_URL } from '../constants/routes';
import { useConnectNowButtonHandlers } from '../hooks/useConnectNowButtonHandlers';
import { useTranslation } from '../hooks/useKeyedTranslation';
import RAAMClinicReconnectionPrompt from './Dialog/RAAMClinicReconnectionPrompt';

const StyledContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '1152px',
  minWidth: 0,
  paddingLeft: '1rem',
  paddingRight: 'calc(1rem - 100vw + 100%)',

  [theme.breakpoints.up('sm')]: {
    paddingLeft: '1.5rem',
    paddingRight: 'calc(1.5rem - 100vw + 100%)',
  },
}));

const StyledFooterText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: '19px',
  letterSpacing: '0.25px',
  textAlign: 'center',
  color: theme.palette.primary.main,
}));

const FooterStack = styled(Stack)(({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const StyledCheckinStack = styled(Stack)(() => ({
  justifyContent: 'center',
}));

const StyledFooter = styled(Box)(() => ({
  marginTop: 'auto',
  display: 'flex',
  justifyContent: 'center',
}));

export default function Footer(): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();

  const shouldHaveCheckInSupport = useMemo(() => {
    if (location.pathname.match(ROUTE_URL.connect_now.index)) {
      return false;
    }

    return location.pathname !== ROUTE_URL.base;
  }, [location.pathname]);

  const [isModalOpen, connectNowHandler, closeModal] =
    useConnectNowButtonHandlers();

  return (
    <StyledFooter component="footer">
      <StyledContainer>
        <FooterStack
          spacing={2}
          mt={2}
          sx={
            shouldHaveCheckInSupport
              ? {
                  borderRadius: '12px',
                  background: 'inherit',
                  backdropFilter: 'blur(6.7957px) !important',
                  bgcolor: '#FFFFFFA6',
                  paddingY: (themeCb) => themeCb.spacing(2),
                }
              : undefined
          }
        >
          {shouldHaveCheckInSupport && (
            <>
              <StyledCheckinStack spacing={2}>
                <StyledFooterText textAlign="center">
                  {t('find_clinic.seeking_support_check_in.support_message')}
                </StyledFooterText>

                <Box display="flex" justifyContent="center">
                  <Button
                    type="button"
                    variant="contained"
                    title={t(
                      'find_clinic.seeking_support_check_in.button.title'
                    )}
                    aria-label={t(
                      'find_clinic.seeking_support_check_in.button.aria_label'
                    )}
                    onClick={connectNowHandler}
                  >
                    {t('find_clinic.seeking_support_check_in.button.label')}
                  </Button>
                </Box>
              </StyledCheckinStack>
              <Divider />
            </>
          )}

          <Stack>
            <StyledFooterText>{t('footer.body_1')}</StyledFooterText>
            <StyledFooterText>{t('footer.body_2')}</StyledFooterText>
          </Stack>
        </FooterStack>
      </StyledContainer>
      <RAAMClinicReconnectionPrompt
        open={isModalOpen}
        closeDialog={closeModal}
      />
    </StyledFooter>
  );
}
