import { useCurrentLanguage } from 'hive-react-utils';
import { useCallback } from 'react';
import { IDirtyText } from '../contexts/DataContext';

export default function useDirtyTextReader() {
  const lang = useCurrentLanguage();
  return useCallback(
    (text: IDirtyText | {}) =>
      'en' in text ? (text.fr && lang === 'fr' ? text.fr : text.en) : '',
    [lang]
  );
}
