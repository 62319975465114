const APP_TITLE = 'RAAM One Front Door';

export const en = {
  app: {
    title: APP_TITLE,
  },
  header: {
    title: APP_TITLE,
    login: 'Sign In',
    logout: 'Sign Out',
  },
  footer: {
    body_1: 'RAAM is not an emergency service.',
    body_2:
      'If you are currently experiencing an emergency, please go to your nearest emergency department or call 911.',
  },
  signin: {
    please_sign_in: 'Please sign in...',
    title: 'Sign In',
    message: 'Use your credentials to sign into the virtual waiting room.',
    email_label: 'Email Address:',
    email_placeholder: 'please enter your email address',
    password_label: 'Password:',
    password_placeholder: 'please enter password',
    remember_me: 'Remember me',
    button_label: 'Sign In',
    forgot_password: 'Forgot password?',
  },
  forgot_password: {
    title: 'Forgot Password',
    message: 'Enter your email to begin resetting your password.',
    email_label: 'Email Address:',
    email_placeholder: 'please enter your email address',
    button_label: {
      cancel: 'Cancel',
      submit: 'Submit',
    },
    success:
      'An email has been sent to {{email}} with instructions for resetting your password.',
  },
  change_password: {
    title: 'Change Password',
    message: 'Enter your New Password',
    password_label: 'Password',
    confirm_password_label: 'Confirm Password',
    button_label: {
      cancel: 'Cancel',
      change: 'Change Password',
    },
    success: 'You can now |sign in| with your new password.',
    success_sign_in: 'Sign In',
    error: {
      min: 'Password must contain a minimum of 8 characters',
      lowercase: 'Password must contain at least one lowercase character',
      uppercase: 'Password must contain at least one uppercase character',
      digits: 'Password must contain at least one number',
      spaces: 'Password must not contain spaces',
    },
  },
  language_toggle: {
    aria_label: 'Toggle language to French',
    title: 'Toggle language to French',
  },
  find_clinic: {
    description: {
      based: 'Based on your current location',
      filter: 'and selected filters',
      zero: 'there are <strong>0 RAAM clinics</strong>.',
      one: 'there is <strong>1 RAAM clinic</strong>.',
      many: 'there are <strong>{{count}} RAAM clinics</strong>.',
    },
    button: {
      filter: {
        label: 'Filter One Front Door results',
        distance: 'Distance from my location...',
        clinic_access: 'Clinic Access',
        switches: {
          acceptsVirtual: 'Show only virtual Meetings',
          isOpen: 'Show only open clinics',
        },
      },
      location: {
        label: 'Change location',
        description: 'To see clinics in another location, enter a postal code',
        postal_code: 'Postal Code',
        automatic: 'Use my location to find clinics',
        cancel: 'Cancel',
        continue: 'Continue',
      },
      check_in_button_label: 'Connect now',
      clinic_status_icon: {
        open: 'Clinic is open',
        closed: 'Clinic is closed',
      },
    },
    tooltip: {
      link_button: 'Open link in new tab',
      phone_button: 'Open link to call number',
      location_button: 'Open link to view location',
      check_in_button: 'Connect now for a Virtual Visit',
      virtual_clinic_hours: 'Virtual Clinic Hours',
      walk_in_clinic_hours: 'Walk-in Clinic Hours',
    },
    input_location: {
      header: 'Find a RAAM clinic near you',
      body: 'In order to connect you with the most appropriate clinical team, we need to know where you reside.',
      placeholder: 'Enter postal code (e.g., N6G 1G9)',
      placeholder_small: 'Enter postal code',
      helper_text: 'Enter your postal code to find RAAM clinics near you.',
      error_text: 'Please enter a valid postal code.',
      next_step: 'Continue',
      button_aria_label: 'Submit postal code',
      automatic: 'Use my location',
      info: {
        available:
          'Location services are enabled for this site. We will use your current device location to find clinics in your area. Your location data will not be kept and will not be used for any tracking purposes.',
        denied:
          'Location services are disabled for this site, please reset the permissions in your application settings.',
        unavailable:
          'We were not able to determine your location due to an unknown error.',
        timeout:
          'The request to retrieve your location timed out. Please try again, or enter your postal code manually.',
        insecure:
          'We are not able to determine your location as this page was not served over HTTPS.',
        waiting:
          'We are waiting for your browser to give us location access. If you see a pop-up asking you for permission to do this, please accept.',
      },
      geolocation_wait_aria_label: 'Waiting for geolocation access',
    },
    seeking_support_check_in: {
      button: {
        aria_label: 'Connect now to a RAAM clinic to seek support services',
        title: 'Connect now to a RAAM clinic to seek support services',
        label: 'Connect now',
      },
      support_message:
        'If you are seeking support services, please connect now, and you will be connected with one of our virtual counselling network team members.',
      emergency_message_primary: 'RAAM is not an emergency service.',
      emergency_message_secondary:
        'If you are experiencing an emergency, please go to your nearest emergency department or call 911.',
      closed: {
        warning: 'The Virtual Clinic is not currently seeing clients.',
        info: 'For more information or assistance in the interim you can visit the following:',
      },
    },
    postal_code_errors: {
      not_found: 'Postal code not found.',
      unreachable: 'Geocoding request failed.',
    },
    clinic_icon_legend: {
      title: 'Virtual Clinic:',
      open: 'Open',
      closed: 'Closed',
    },
  },
  not_implemented: 'State "{{connectionState}}" not implemented"',
  404: "We're sorry, you seem to have lost connection to our servers. Please reload the page, or go back to our home page:",
  homepage: 'Homepage',
  landing: {
    title: 'Welcome to AccessRAAM Ontario',
    subheading: 'What is AccessRAAM?',
    body: 'Access RAAM makes it easier to help find mental health and/or substance use health/addictions support, services, and care. We eliminate the guesswork and work with you to refer you to the services you need, all while supporting you through the process. After reaching out to us, you will be paired with a trained mental health, substance use health and addictions professional who will connect you to the services you need from a network of partner organizations.',
    cards: {
      additional_resources: {
        header: 'Are you looking for information?',
        body: 'Are you looking for information or resources for you, or someone you know with substance use issues / concerns.',
        link_button: {
          label: 'Learn More',
        },
        raam_clinic_network: {
          link_button: {
            label: 'RAAM Clinic Network',
          },
          body: 'is a province wide network of clinics offering in-person, teleconference or virtual consultation services for substance use and mental health.',
        },
        for_patients: {
          link_button: {
            label: 'For Patients to learn more',
          },
          body: 'about withdrawal, treatment, therapy, best practices, personal stories and getting help.',
        },
        for_families: {
          link_button: {
            label: 'For Families Friends & Loved Ones',
          },
          body: 'to learn more about substance use, mental health and getting help services.',
        },
        for_professionals: {
          link_button: {
            label: 'For Professionals',
          },
          body: 'to learn more about substance use, mental health and referrals.',
        },
      },
      find_clinic: {
        header: 'Find a RAAM Clinic near you',
        body: 'Are you looking for a RAAM clinic in your area for a walk-in or virtual consultation, simply let us know where you are and we’ll show you the clinics near you.',
        button: {
          label: 'Find A Clinic',
          title: 'Click now to find a RAAM clinic',
          aria_label: 'Click now to find a RAAM clinic',
        },

        image: {
          alt: 'Find a RAAM Clinic Map',
        },
      },
      connect_now: {
        header: 'Connecting you to the help you need',
        subheading1: 'Find a clinic near you',
        body1:
          'Click the "Connect Now" button below to see the available service partners near you for either an immediate online consultation, or find an in person service partner near you.',
        subheading2: 'Connect now for a consultation',
        body2:
          'Complete a secure and easy online form to identify the services you need, and get into the queue for a virtual meeting with one of our network of consultants.',
        subheading3: 'Get the help you need',
        body3:
          'During your consultation you can meet with multiple consultants in a single call to ensure that you get the appropriate services and support that you need all in one place.',
        button: {
          label: 'Connect Now',
          title: 'Click now to connect with a RAAM clinic',
          aria_label: 'Click now to connect with a RAAM clinic',
        },
      },
      whosAccessRAAM: {
        header: 'Who is AccessRAAM for?',
        body1: 'AccessRAAM is for anyone:',
        points: {
          point1: '16 years of age and older',
          point2: 'presently located in Ontario',
          point3: 'and/or substance use health/addiction services.',
        },
        body2:
          'If you need help and support, and are not sure where to go, reach out to us and we will help connect you to the service you need.',
      },
      whoAreWe: {
        header: 'Who are we?',
        body: 'The Rapid Access Addiction Medicine (RAAM) Clinic offers services for people who may have concerns about their alcohol and/or opioid use. We help by:',
        points: {
          point1: 'reviewing your treatment goals with you,',
          point2:
            'providing medication (if needed) for opioid and/or alcohol problems, providing brief individual counselling,',
          point3:
            'and connecting you with the right community resources for your needs.',
        },
      },
    },
  },
  connect_now: {
    header:
      'Have you consulted with a RAAM clinic – in-person, by phone, or on-line – in the last six months, or do you have an ongoing relationship with a RAAM clinic?',
    yes_button: {
      label: 'Yes',
      title: 'Yes',
      aria_label: 'Yes',
    },
    no_button: {
      label: 'No',
      title: 'No',
      aria_label: 'No',
    },
    continue_button: {
      label: 'Continue',
      title: 'Continue',
      aria_label: 'Continue',
    },
    select_clinic: {
      header:
        'When you previously visited the RAAM clinic either in-person or virtually, which clinic did you visit, or if you have a relationship with a clinic, please select it?',
      label: 'Select RAAM Clinic',
    },
    find_clinic: {
      header:
        'In order to connect you with the most appropriate clinical team, we need to know where you reside.',
    },
  },
  visited_clinic: {
    header: 'You’ve been here before',
    body: 'Last time you were here you connected with {{previousClinic}}, would you like to re-connect with them now?',
    close_button: {
      title: 'Close dialog',
      aria_label: 'Close dialog',
    },
  },
  resource_support: {
    title: 'Resources and Support',
    body1:
      'The Royal’s Substance Use and Concurrent Disorders Program (SUCD) serves people living with complex substance (drug or alcohol) use, mental health, and physical health issues. This program offers a range of inpatient and outpatient services for people living with substance use and mental health concerns, which together are called concurrent disorders.',
    body2:
      'The Substance Use and Concurrent Disorders Program provides specialized, evidence-based services to help our clients and their families optimize health and well-being. We will work with each client to understand their goals and determine the best treatment and supports to achieve those goals.',
    raam_clinic_network: {
      title: 'RAAM Digital Front Door (DFD) Partnership',
      about: {
        title: 'About the RAAM Digital Front Door (DFD) Partnership',
        description:
          'Aenean lacinia bibendum nulla sed consectetur. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec ullamcorper nulla non metus auctor fringilla. Nullam id dolor id nibh ultricies vehicula ut id elit. Nulla vitae elit libero, a pharetra augue. Maecenas sed diam eget risus varius blandit sit amet non magna.',
      },
      find_clinic: {
        title: 'All Clinics in Ontario',
        description:
          'Donec sed odio dui. Donec id elit non mi porta gravida at eget metus. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Maecenas faucibus mollis interdum.',
      },
      partners: {
        title: 'The RAAM DFD Partners',
        description:
          'Cras mattis consectetur purus sit amet fermentum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor.',
      },
    },
    for_patients: {
      title: 'For Patients',
      general: {
        title: 'Understanding Substance Use',
        description:
          'Many people think of substance use problems only in terms of addiction, a dependence on alcohol or other drugs where someone needs to continually use the substance in order to feel normal. Substance use is bigger than that.',
        accordion: {
          alcohol: {
            title: 'Alcohol',
            cards: {
              download: {
                title: 'Downloadable Resource Title',
                description:
                  'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
                link: 'Document Link Title',
              },
              video: {
                title: 'Video Resource Title',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras mattis consectetur purus sit amet fermentum.',
                link: 'Video Link Title',
              },
              article: {
                title: 'Article Resource Title',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras mattis consectetur purus sit amet fermentum.',
                link: 'Read Article',
              },
              external: {
                title: 'External Resource Title',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.',
                link: 'External Site Link Title',
              },
            },
          },
          opioids: {
            title: 'Opioids',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          benzodiazepines: {
            title: 'Benzodiazepines',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      alcohol: {
        title: 'Alcohol Use',
        description:
          'Donec sed odio dui. Donec id elit non mi porta gravida at eget metus. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Maecenas faucibus mollis interdum.',
        accordion: {
          withdrawal: {
            title: 'Withdrawal Management',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          treatment: {
            title: 'Treatment',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          best_practices: {
            title: 'Best Practices',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          personal_stories: {
            title: 'Personal Stories',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      opioids: {
        title: 'Opioid Use',
        description:
          'Donec sed odio dui. Donec id elit non mi porta gravida at eget metus. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Maecenas faucibus mollis interdum.',
        accordion: {
          withdrawal: {
            title: 'Withdrawal Management',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          agonist: {
            title: 'Opioid Agonist Therapy',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          treatment: {
            title: 'Treatment',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          best_practices: {
            title: 'Best Practices',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          personal_stories: {
            title: 'Personal Stories',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      mental_health: {
        title: 'Mental Health',
        description:
          'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Maecenas faucibus mollis interdum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.',
        accordion: {
          concurrent_disorders: {
            title: 'Concurrent Disorders',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      getting_help: {
        title: 'Getting Help',
        description:
          'Cras mattis consectetur purus sit amet fermentum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Nullam quis risus eget urna mollis ornare vel eu leo.',
        accordion: {
          emergency: {
            title: 'When to Seek Emergency Services',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          crisis: {
            title: 'Crisis Line & Supportline Numbers',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          raam: {
            title: 'What is RAAM?',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          dfd: {
            title: 'What is the DFD?',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
    },
    for_families: {
      title: 'For Families Friends & Loved Ones',
      general: {
        title: 'Understanding Substance Use',
        description:
          'Many people think of substance use problems only in terms of addiction, a dependence on alcohol or other drugs where someone needs to continually use the substance in order to feel normal. Substance use is bigger than that.',
        accordion: {
          alcohol: {
            title: 'Alcohol',
            cards: {
              download: {
                title: 'Downloadable Resource Title',
                description:
                  'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
                link: 'Document Link Title',
              },
              video: {
                title: 'Video Resource Title',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras mattis consectetur purus sit amet fermentum.',
                link: 'Video Link Title',
              },
              article: {
                title: 'Article Resource Title',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras mattis consectetur purus sit amet fermentum.',
                link: 'Read Article',
              },
              external: {
                title: 'External Resource Title',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.',
                link: 'External Site Link Title',
              },
            },
          },
          opioids: {
            title: 'Opioids',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          benzodiazepines: {
            title: 'Benzodiazepines',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      mental_health: {
        title: 'Mental Health',
        description:
          'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Maecenas faucibus mollis interdum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.',
        accordion: {
          concurrent_disorders: {
            title: 'Concurrent Disorders',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      getting_help: {
        title: 'Getting Help',
        description:
          'Cras mattis consectetur purus sit amet fermentum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Nullam quis risus eget urna mollis ornare vel eu leo.',
        accordion: {
          emergency: {
            title: 'When to Seek Emergency Services',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          crisis: {
            title: 'Crisis Line & Supportline Numbers',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          raam: {
            title: 'What is RAAM?',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          dfd: {
            title: 'What is the DFD?',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
    },
    for_professionals: {
      title: 'For Professionals',
      general: {
        title: 'Understanding Substance Use',
        description:
          'Many people think of substance use problems only in terms of addiction, a dependence on alcohol or other drugs where someone needs to continually use the substance in order to feel normal. Substance use is bigger than that.',
        accordion: {
          alcohol: {
            title: 'Alcohol',
            cards: {
              download: {
                title: 'Downloadable Resource Title',
                description:
                  'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
                link: 'Document Link Title',
              },
              video: {
                title: 'Video Resource Title',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras mattis consectetur purus sit amet fermentum.',
                link: 'Video Link Title',
              },
              article: {
                title: 'Article Resource Title',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras mattis consectetur purus sit amet fermentum.',
                link: 'Read Article',
              },
              external: {
                title: 'External Resource Title',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.',
                link: 'External Site Link Title',
              },
            },
          },
          opioids: {
            title: 'Opioids',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          benzodiazepines: {
            title: 'Benzodiazepines',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      mental_health: {
        title: 'Mental Health',
        description:
          'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Maecenas faucibus mollis interdum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.',
      },
      referrals: {
        title: 'Referrals',
        description:
          'Cras mattis consectetur purus sit amet fermentum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Nullam quis risus eget urna mollis ornare vel eu leo.',
      },
    },
  },
  lorem: {
    short: 'Lorem ipsum',
    medium:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
    long: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nisi sapien, dapibus in ultrices vitae, gravida a sapien. Nullam eu lorem convallis, convallis orci eget, tempus ipsum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla facilisi. Nunc orci diam, efficitur ac est eu, varius porttitor nisl. Aenean bibendum semper nulla, eget sollicitudin orci dignissim id. Curabitur hendrerit hendrerit dui, vel egestas diam bibendum sit amet. Pellentesque aliquam bibendum turpis, nec blandit enim posuere id. Ut pulvinar leo sit amet facilisis porta. Pellentesque tempor lacus at lorem dignissim volutpat. Vestibulum sed odio non velit pulvinar mattis. Vestibulum a iaculis massa. Curabitur ac urna aliquam metus suscipit viverra id non elit.',
  },
  resource_card: {
    link: {
      file: {
        label: 'Download File',
      },
      resource: {
        label: 'Read Article',
      },
      video: {
        label: 'View Video',
      },
    },
  },
  common: {
    button: {
      close: {
        label: 'Close',
        title: 'Close',
        aria_label: 'Close',
      },
      back: {
        label: 'Back',
        title: 'Back',
        aria_label: 'Back',
      },
    },
  },
};
