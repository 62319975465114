import { Box, Grid, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { MEDIUM_GRAY } from '../../constants/colors';
import {
  ClinicFilterContextProvider,
  DEFAULT_MAX_DISTANCE,
} from '../../contexts/ClinicFilterContext';
import ScrollContextProvider from '../../contexts/ScrollContext/ScrollContextProvider';
import { useClinicDistanceSliderRange } from '../../hooks/useClinicDistanceSliderRange';
import { useFindClinicContext } from '../../hooks/useFindClinicContext';
import { useGeoLocationCallback } from '../../hooks/useGeoLocationCallback';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useTranslation } from '../../hooks/useKeyedTranslation';
import { useScrollContext } from '../../hooks/useScrollContext';
import {
  ClinicDataType,
  clearLocationParams,
  guessMapZoomByDistance,
  narrowToLatLngLiteral,
  setMapZoomByDistance,
} from '../../utils';
import ClinicList from './ClinicList';
import { FindClinicDescription } from './FindClinicDescription';
import FindClinicFilterButton from './FindClinicFilterButton';
import FindClinicMap from './FindClinicMap';
import RefineLocationButton from './RefineLocationButton';

export type ClinicClickHandler = (
  clinicIndex: number | null,
  clinic?: ClinicDataType,
  cb?: () => void
) => () => void;

const GOOGLE_API_KEY =
  process.env.REACT_APP_GOOGLE_API_KEY ||
  (window as any)._env_.REACT_APP_GOOGLE_API_KEY;

const FindClinic = memo(() => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const { t } = useTranslation();

  const { coordinates, setCoordinates, setCoordFromPostalCode } =
    useFindClinicContext();

  const acquireGeoLocation = useGeoLocationCallback();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const postalCodeParamVal = searchParams.get('postalCode');
    const latitudeParamVal = searchParams.get('lat');
    const longitudeParamVal = searchParams.get('lng');
    if (!coordinates) {
      if (latitudeParamVal && longitudeParamVal) {
        setCoordinates({
          lat: Number(latitudeParamVal),
          lng: Number(longitudeParamVal),
        });
      } else if (postalCodeParamVal) {
        setCoordFromPostalCode(postalCodeParamVal);
      } else {
        acquireGeoLocation?.();
      }
      // If no coordinates set, will just use `DEFAULT_MAP_CENTER` (which is near Ottawa)
    } else {
      // If coordinates are known but no parameters are set, add them to the URL
      if (!postalCodeParamVal && !latitudeParamVal && !longitudeParamVal) {
        setSearchParams(
          (prev) => {
            const literalCoords = narrowToLatLngLiteral(coordinates);
            return {
              ...clearLocationParams(prev),
              lat: `${literalCoords.lat}`,
              lng: `${literalCoords.lng}`,
            };
          },
          { replace: true }
        );
      }
    }
  }, [
    searchParams,
    setSearchParams,
    coordinates,
    setCoordinates,
    setCoordFromPostalCode,
    location.search,
    acquireGeoLocation,
  ]);

  const { scrollToItem, setSelectedRef } = useScrollContext();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [prevMapZoom, setPrevMapZoom] = useState<number>();
  const [prevMapCentre, setPrevMapCentre] = useState<google.maps.LatLng>();

  const onDistanceSliderChange = useCallback(
    (distance: number) => {
      setPrevMapZoom(undefined);
      setPrevMapCentre(undefined);
      setSelectedRef(null);
      if (map && coordinates) {
        map.setCenter(coordinates);
        setMapZoomByDistance(map, distance);
      }
    },
    [setSelectedRef, map, coordinates]
  );

  const { sliderMax, sliderMin } = useClinicDistanceSliderRange();
  const sliderInit = Math.min(
    Math.max(DEFAULT_MAX_DISTANCE, sliderMin),
    sliderMax
  );

  useEffect(() => {
    onDistanceSliderChange(sliderInit);
  }, [onDistanceSliderChange, sliderInit]);

  const clinicOnClickHandler: ClinicClickHandler = useCallback(
    (clinicIndex, clinic, cb) => {
      return () => {
        if (map) {
          if (clinic) {
            if (!prevMapCentre) {
              setPrevMapCentre(map.getCenter());
            }
            if (!prevMapZoom) {
              setPrevMapZoom(map.getZoom());
            }
            map.panTo(clinic);
            map.setZoom(15);
          } else {
            if (prevMapCentre) {
              map.panTo(prevMapCentre);
              setPrevMapCentre(undefined);
            }
            if (prevMapZoom) {
              map.setZoom(prevMapZoom);
              setPrevMapZoom(undefined);
            }
          }
        }

        if (typeof clinicIndex === 'number') {
          scrollToItem(clinicIndex);
        }
        setSelectedRef(clinicIndex);

        cb?.();
      };
    },
    [map, prevMapCentre, prevMapZoom, scrollToItem, setSelectedRef]
  );

  return (
    <Grid
      container
      direction="row"
      alignItems="stretch"
      maxWidth="100%"
      minHeight={isMobile ? '' : '42em'}
      rowSpacing={{ xs: 2, sm: 0.5 }}
      padding={{ xs: 2.5 }}
      columnSpacing={{ xs: 0, sm: 2 }}
      sx={{
        margin: '0 !important',
        flexGrow: 1,
        borderRadius: '12px',
        background: 'inherit',
        backdropFilter: 'blur(6.7957px) !important',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
        bgcolor: '#FFFFFFA6',
      }}
    >
      <Grid
        item
        xs={isMobile ? 12 : 4.5}
        sx={isMobile ? { paddingTop: '0 !important' } : undefined}
      >
        <Box
          display="flex"
          flexDirection="column"
          rowGap={0.5}
          maxHeight="628px"
        >
          {!isMobile && (
            <Typography
              className="find-a-clinic--header"
              variant="h2"
              textAlign="left"
            >
              {t('find_clinic.input_location.header')}
            </Typography>
          )}

          <Box display="flex" marginY={0.5}>
            <FindClinicDescription flexGrow={1} />
            <Box
              display="inline-flex"
              gap={1}
              flexBasis="content"
              marginLeft="0.5rem"
            >
              <RefineLocationButton />
              <FindClinicFilterButton
                onMaxDistanceSliderChange={onDistanceSliderChange}
              />
            </Box>
          </Box>

          {!isMobile && <ClinicList onClinicClick={clinicOnClickHandler} />}
        </Box>
      </Grid>

      <Grid item container xs={isMobile ? 12 : 7.5} minHeight={{ xs: '314px' }}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          sx={{
            border: `2px solid ${MEDIUM_GRAY}`,
            borderRadius: '8px',
            overflow: 'hidden',
          }}
        >
          <FindClinicMap
            mapId="google-map-script"
            apiKey={GOOGLE_API_KEY}
            zoom={guessMapZoomByDistance(sliderInit, isMobile)}
            containerStyle={{
              width: '100%',
              height: '100%',
            }}
            setMap={setMap}
            clinicOnClickHandler={clinicOnClickHandler}
          />
        </Grid>
      </Grid>

      {isMobile && (
        <Grid item xs={12} display="flex" flexDirection="column" gap={0.5}>
          <ClinicList onClinicClick={clinicOnClickHandler} />
        </Grid>
      )}
    </Grid>
  );
});
FindClinic.displayName = 'FindClinic';

interface FindClinicProps {
  dfdOnly?: boolean;
}

const FindClinicContainer = ({ dfdOnly }: FindClinicProps) => {
  return (
    <ScrollContextProvider>
      <ClinicFilterContextProvider dfdOnly={dfdOnly}>
        <FindClinic />
      </ClinicFilterContextProvider>
    </ScrollContextProvider>
  );
};

export default memo(FindClinicContainer);
