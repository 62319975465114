import { Box, Link, Slide, Stack, Typography } from '@mui/material';
import { useConnectionState } from 'hive-react-utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface MessageProps {
  lng: 'en' | 'fr';
}
const Message = ({ lng }: MessageProps) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Typography color="primary" textAlign="center">
        {t('404', { lng })}
      </Typography>
      <Link href="/">{t('homepage', { lng })}</Link>
    </Box>
  );
};

export default function NotImplemented(): JSX.Element {
  const connectionState = useConnectionState();

  useEffect(() => {
    console.debug(connectionState);
  }, [connectionState]);

  return (
    <Stack
      flexGrow="1"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Slide direction="up" in timeout={1000}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
        >
          <Message lng="en" />/
          <Message lng="fr" />
        </Box>
      </Slide>
    </Stack>
  );
}
