import { memo } from 'react';
import {
  CenteredColumnContainer,
  CircleText,
  CircleWrapper,
  ContentText,
  ContentTitle,
} from '.';

interface ConnectingCardProps {
  circleText: string;
  cardTitle: string;
  cardText: string;
}
export const ConnectingCard = memo(
  ({ circleText, cardTitle, cardText }: ConnectingCardProps) => {
    return (
      <CenteredColumnContainer maxWidth="20rem">
        <CircleWrapper component="span">
          <CircleText variant="body1" color="textPrimary">
            {circleText}
          </CircleText>
        </CircleWrapper>

        <ContentTitle textAlign="center">{cardTitle}</ContentTitle>

        <ContentText textAlign="left">{cardText}</ContentText>
      </CenteredColumnContainer>
    );
  }
);
ConnectingCard.displayName = 'ConnectingCard';
