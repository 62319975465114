import { ThemeProvider } from '@mui/material';
import { useAppVersion, useTitleEffect } from 'hive-react-utils';
import { useEffect } from 'react';
import { DataContextProvider } from './contexts/DataContext';
import Main from './Main';
import { theme } from './theme';

export default function App() {
  useTitleEffect('app.title');

  const { version } = useAppVersion();
  useEffect(() => console.debug(`App Version: ${version}`), [version]);

  return (
    <ThemeProvider theme={theme}>
      <DataContextProvider>
        <Main />
      </DataContextProvider>
    </ThemeProvider>
  );
}
