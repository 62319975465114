import { Grid, Tooltip } from '@mui/material';
import { memo } from 'react';
import useDirtyTextReader from '../../../../hooks/useDirtyTextReader';
import { useTranslation } from '../../../../hooks/useKeyedTranslation';
import { ClinicDataType, isDFDClinic } from '../../../../utils';
import { ClinicLinkDetails } from './ClinicLinkDetails';
import { ClinicListSchemaType } from './ClinicListSchema';
import { ClinicLocationDetails } from './ClinicLocationDetails';
import { ClinicPhoneDetailsFactory } from './ClinicPhoneDetails';
import { ClinicTextDetails } from './ClinicTextDetails';

const COMPONENT_MAP = {
  text: ClinicTextDetails,
  link: ClinicLinkDetails,
  tel: ClinicPhoneDetailsFactory,
  location: ClinicLocationDetails,
};

export interface ClinicDetailRowProps {
  clinicSchema: ClinicListSchemaType;
  clinic: ClinicDataType;
}

export const ClinicDetailRowFactory = memo(
  ({ clinicSchema, clinic }: ClinicDetailRowProps) => {
    const { t } = useTranslation();
    const dirtyTextReader = useDirtyTextReader();

    // These variables must be assigned inside the switch case or else TypeScript gets mad
    let component: JSX.Element;
    let ComponentToRender: (typeof COMPONENT_MAP)[keyof typeof COMPONENT_MAP];
    let details: string | undefined;

    switch (clinicSchema.content.details) {
      case 'telephone':
        ComponentToRender = COMPONENT_MAP[clinicSchema.content.type];
        component = (
          <ComponentToRender details={clinic[clinicSchema.content.details]} />
        );
        break;
      case 'dfdLink':
        ComponentToRender = COMPONENT_MAP[clinicSchema.content.type];
        if (!isDFDClinic(clinic)) {
          return null;
        }
        details = clinic[clinicSchema.content.details];
        component = <ComponentToRender details={details} />;
        break;
      case 'address':
      case 'hours':
        ComponentToRender = COMPONENT_MAP[clinicSchema.content.type];
        component = (
          <ComponentToRender
            details={dirtyTextReader(clinic[clinicSchema.content.details])}
          />
        );
        break;
      case 'walkInHours':
        ComponentToRender = COMPONENT_MAP[clinicSchema.content.type];
        const walkInDetails = clinic[clinicSchema.content.details];
        if (!walkInDetails) {
          return null;
        }
        component = (
          <ComponentToRender details={dirtyTextReader(walkInDetails)} />
        );
        break;
      default:
        ComponentToRender = COMPONENT_MAP[clinicSchema.content.type];
        details = clinic[clinicSchema.content.details];
        if (!details) {
          return null;
        }
        component = <ComponentToRender details={details} />;
    }

    return (
      <>
        <Grid
          item
          xs={1}
          display="flex"
          justifyContent="right"
          paddingRight="0.25rem"
        >
          {clinicSchema.icon}
        </Grid>
        {['hours', 'walkInHours'].includes(clinicSchema.content.details) &&
        isDFDClinic(clinic) ? (
          <Tooltip
            title={
              clinicSchema.content.details === 'walkInHours'
                ? t('find_clinic.tooltip.walk_in_clinic_hours')
                : t('find_clinic.tooltip.virtual_clinic_hours')
            }
          >
            {
              <Grid item xs={9}>
                {component}
              </Grid>
            }
          </Tooltip>
        ) : (
          <Grid item xs={9}>
            {component}
          </Grid>
        )}
      </>
    );
  }
);
ClinicDetailRowFactory.displayName = 'ClinicDetailRowFactory';
