import { I18nAccess } from '../hooks/useKeyedTranslation';

export interface Resource {
  id: string;
  title: I18nAccess;
  content: Section[];
}

export interface Section {
  title: I18nAccess;
  content: I18nAccess;
  display?: 'ConnectNow' | 'Clinics' | InnerAccordion;
}

export type InnerAccordion = InnerAccordionSection[];

export type InnerAccordionSection =
  | CardInnerAccordionSection
  | TextInnerAccordionSection;

export type CardInnerAccordionSection = {
  title: I18nAccess;
  content: Card[];
};

export type TextInnerAccordionSection = {
  title: I18nAccess;
  content: I18nAccess;
};

export function isCardInnerAccordionSection(
  section: InnerAccordionSection
): section is CardInnerAccordionSection {
  return typeof section === 'object' && Array.isArray(section.content);
}

export function isTextInnerAccordionSection(
  section: InnerAccordionSection
): section is TextInnerAccordionSection {
  return typeof section === 'object' && typeof section.content === 'string';
}

export type CardResourceType = 'resource' | 'video' | 'file';

export type ModalContent = Pick<Section, 'title' | 'content'> & {
  videoLink?: string;
};

export interface Card {
  id: string;
  title: I18nAccess;
  description: I18nAccess;
  type: CardResourceType;
  tags?: string[];
  content?: ModalContent[];
}

export const RESOURCE_SUPPORT: readonly Resource[] = [
  {
    id: 'raam-clinic',
    title: 'resource_support.raam_clinic_network.title',
    content: [
      {
        title: 'resource_support.raam_clinic_network.about.title',
        content: 'resource_support.raam_clinic_network.about.description',
      },
      {
        title: 'resource_support.raam_clinic_network.find_clinic.title',
        content: 'resource_support.raam_clinic_network.find_clinic.description',
        display: 'ConnectNow',
      },
      {
        title: 'resource_support.raam_clinic_network.partners.title',
        content: 'resource_support.raam_clinic_network.partners.description',
        display: 'Clinics',
      },
    ],
  },
  {
    id: 'for-patients',
    title: 'resource_support.for_patients.title',
    content: [
      {
        title: 'resource_support.for_patients.general.title',
        content: 'resource_support.for_patients.general.description',
        display: [
          {
            title:
              'resource_support.for_patients.general.accordion.alcohol.title',
            content: [
              {
                id: 'file-1',
                type: 'file',
                title:
                  'resource_support.for_patients.general.accordion.alcohol.cards.download.title',
                description:
                  'resource_support.for_patients.general.accordion.alcohol.cards.download.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                ],
              },
              {
                id: 'video-1',
                type: 'video',
                title:
                  'resource_support.for_patients.general.accordion.alcohol.cards.video.title',
                description:
                  'resource_support.for_patients.general.accordion.alcohol.cards.video.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                    videoLink: 'https://www.youtube.com/embed/7PIji8OubXU',
                  },
                ],
              },
              {
                id: 'article-1',
                type: 'resource',
                title:
                  'resource_support.for_patients.general.accordion.alcohol.cards.article.title',
                description:
                  'resource_support.for_patients.general.accordion.alcohol.cards.article.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                ],
              },
              {
                id: 'article-2',
                type: 'resource',
                title:
                  'resource_support.for_patients.general.accordion.alcohol.cards.article.title',
                description:
                  'resource_support.for_patients.general.accordion.alcohol.cards.article.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                ],
              },
              {
                id: 'article-3',
                type: 'resource',
                title:
                  'resource_support.for_patients.general.accordion.alcohol.cards.external.title',
                description:
                  'resource_support.for_patients.general.accordion.alcohol.cards.external.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                ],
              },
            ],
          },
          {
            title:
              'resource_support.for_patients.general.accordion.opioids.title',
            content:
              'resource_support.for_patients.general.accordion.opioids.description',
          },
          {
            title:
              'resource_support.for_patients.general.accordion.benzodiazepines.title',
            content:
              'resource_support.for_patients.general.accordion.benzodiazepines.description',
          },
        ],
      },
      {
        title: 'resource_support.for_patients.alcohol.title',
        content: 'resource_support.for_patients.alcohol.description',
        display: [
          {
            title:
              'resource_support.for_patients.alcohol.accordion.withdrawal.title',
            content:
              'resource_support.for_patients.alcohol.accordion.withdrawal.description',
          },
          {
            title:
              'resource_support.for_patients.alcohol.accordion.treatment.title',
            content:
              'resource_support.for_patients.alcohol.accordion.treatment.description',
          },
          {
            title:
              'resource_support.for_patients.alcohol.accordion.best_practices.title',
            content:
              'resource_support.for_patients.alcohol.accordion.best_practices.description',
          },
          {
            title:
              'resource_support.for_patients.alcohol.accordion.personal_stories.title',
            content:
              'resource_support.for_patients.alcohol.accordion.personal_stories.description',
          },
        ],
      },
      {
        title: 'resource_support.for_patients.opioids.title',
        content: 'resource_support.for_patients.opioids.description',
        display: [
          {
            title:
              'resource_support.for_patients.opioids.accordion.withdrawal.title',
            content:
              'resource_support.for_patients.opioids.accordion.withdrawal.description',
          },
          {
            title:
              'resource_support.for_patients.opioids.accordion.agonist.title',
            content:
              'resource_support.for_patients.opioids.accordion.agonist.description',
          },
          {
            title:
              'resource_support.for_patients.opioids.accordion.treatment.title',
            content:
              'resource_support.for_patients.opioids.accordion.treatment.description',
          },
          {
            title:
              'resource_support.for_patients.opioids.accordion.best_practices.title',
            content:
              'resource_support.for_patients.opioids.accordion.best_practices.description',
          },
          {
            title:
              'resource_support.for_patients.opioids.accordion.personal_stories.title',
            content:
              'resource_support.for_patients.opioids.accordion.personal_stories.description',
          },
        ],
      },
      {
        title: 'resource_support.for_patients.mental_health.title',
        content: 'resource_support.for_patients.mental_health.description',
        display: [
          {
            title:
              'resource_support.for_patients.mental_health.accordion.concurrent_disorders.title',
            content:
              'resource_support.for_patients.mental_health.accordion.concurrent_disorders.description',
          },
        ],
      },
      {
        title: 'resource_support.for_patients.getting_help.title',
        content: 'resource_support.for_patients.getting_help.description',
        display: [
          {
            title:
              'resource_support.for_patients.getting_help.accordion.emergency.title',
            content:
              'resource_support.for_patients.getting_help.accordion.emergency.description',
          },
          {
            title:
              'resource_support.for_patients.getting_help.accordion.crisis.title',
            content:
              'resource_support.for_patients.getting_help.accordion.crisis.description',
          },
          {
            title:
              'resource_support.for_patients.getting_help.accordion.raam.title',
            content:
              'resource_support.for_patients.getting_help.accordion.raam.description',
          },
          {
            title:
              'resource_support.for_patients.getting_help.accordion.dfd.title',
            content:
              'resource_support.for_patients.getting_help.accordion.dfd.description',
          },
        ],
      },
    ],
  },
  {
    id: 'for-families',
    title: 'resource_support.for_families.title',
    content: [
      {
        title: 'resource_support.for_families.general.title',
        content: 'resource_support.for_families.general.description',
        display: [
          {
            title:
              'resource_support.for_families.general.accordion.alcohol.title',
            content: [
              {
                id: 'file-1',
                type: 'file',
                title:
                  'resource_support.for_families.general.accordion.alcohol.cards.download.title',
                description:
                  'resource_support.for_families.general.accordion.alcohol.cards.download.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                ],
              },
              {
                id: 'video-1',
                type: 'video',
                title:
                  'resource_support.for_families.general.accordion.alcohol.cards.video.title',
                description:
                  'resource_support.for_families.general.accordion.alcohol.cards.video.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                    videoLink: 'https://www.youtube.com/embed/7PIji8OubXU',
                  },
                ],
              },
              {
                id: 'article-1',
                type: 'resource',
                title:
                  'resource_support.for_families.general.accordion.alcohol.cards.article.title',
                description:
                  'resource_support.for_families.general.accordion.alcohol.cards.article.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                ],
              },
              {
                id: 'article-2',
                type: 'resource',
                title:
                  'resource_support.for_families.general.accordion.alcohol.cards.article.title',
                description:
                  'resource_support.for_families.general.accordion.alcohol.cards.article.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                ],
              },
              {
                id: 'article-3',
                type: 'resource',
                title:
                  'resource_support.for_families.general.accordion.alcohol.cards.external.title',
                description:
                  'resource_support.for_families.general.accordion.alcohol.cards.external.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                ],
              },
            ],
          },
          {
            title:
              'resource_support.for_families.general.accordion.opioids.title',
            content:
              'resource_support.for_families.general.accordion.opioids.description',
          },
          {
            title:
              'resource_support.for_families.general.accordion.benzodiazepines.title',
            content:
              'resource_support.for_families.general.accordion.benzodiazepines.description',
          },
        ],
      },
      {
        title: 'resource_support.for_families.mental_health.title',
        content: 'resource_support.for_families.mental_health.description',
        display: [
          {
            title:
              'resource_support.for_families.mental_health.accordion.concurrent_disorders.title',
            content:
              'resource_support.for_families.mental_health.accordion.concurrent_disorders.description',
          },
        ],
      },
      {
        title: 'resource_support.for_families.getting_help.title',
        content: 'resource_support.for_families.getting_help.description',
        display: [
          {
            title:
              'resource_support.for_families.getting_help.accordion.emergency.title',
            content:
              'resource_support.for_families.getting_help.accordion.emergency.description',
          },
          {
            title:
              'resource_support.for_families.getting_help.accordion.crisis.title',
            content:
              'resource_support.for_families.getting_help.accordion.crisis.description',
          },
          {
            title:
              'resource_support.for_families.getting_help.accordion.raam.title',
            content:
              'resource_support.for_families.getting_help.accordion.raam.description',
          },
          {
            title:
              'resource_support.for_families.getting_help.accordion.dfd.title',
            content:
              'resource_support.for_families.getting_help.accordion.dfd.description',
          },
        ],
      },
    ],
  },
  {
    id: 'for-professionals',
    title: 'resource_support.for_professionals.title',
    content: [
      {
        title: 'resource_support.for_professionals.general.title',
        content: 'resource_support.for_professionals.general.description',
        display: [
          {
            title:
              'resource_support.for_professionals.general.accordion.alcohol.title',
            content: [
              {
                id: 'file-1',
                type: 'file',
                title:
                  'resource_support.for_professionals.general.accordion.alcohol.cards.download.title',
                description:
                  'resource_support.for_professionals.general.accordion.alcohol.cards.download.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                ],
              },
              {
                id: 'video-1',
                type: 'video',
                title:
                  'resource_support.for_professionals.general.accordion.alcohol.cards.video.title',
                description:
                  'resource_support.for_professionals.general.accordion.alcohol.cards.video.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                    videoLink: 'https://www.youtube.com/embed/7PIji8OubXU',
                  },
                ],
              },
              {
                id: 'article-1',
                type: 'resource',
                title:
                  'resource_support.for_professionals.general.accordion.alcohol.cards.article.title',
                description:
                  'resource_support.for_professionals.general.accordion.alcohol.cards.article.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                ],
              },
              {
                id: 'article-2',
                type: 'resource',
                title:
                  'resource_support.for_professionals.general.accordion.alcohol.cards.article.title',
                description:
                  'resource_support.for_professionals.general.accordion.alcohol.cards.article.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                ],
              },
              {
                id: 'article-3',
                type: 'resource',
                title:
                  'resource_support.for_professionals.general.accordion.alcohol.cards.external.title',
                description:
                  'resource_support.for_professionals.general.accordion.alcohol.cards.external.description',
                tags: ['Ottawa'],
                content: [
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                  {
                    title: 'lorem.short',
                    content: 'lorem.long',
                  },
                ],
              },
            ],
          },
          {
            title:
              'resource_support.for_professionals.general.accordion.opioids.title',
            content:
              'resource_support.for_professionals.general.accordion.opioids.description',
          },
          {
            title:
              'resource_support.for_professionals.general.accordion.benzodiazepines.title',
            content:
              'resource_support.for_professionals.general.accordion.benzodiazepines.description',
          },
        ],
      },
      {
        title: 'resource_support.for_professionals.mental_health.title',
        content: 'resource_support.for_professionals.mental_health.description',
      },
      {
        title: 'resource_support.for_professionals.referrals.title',
        content: 'resource_support.for_professionals.referrals.description',
      },
    ],
  },
];
