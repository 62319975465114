import { ReactNode } from 'react';
import {
  InnerAccordionSection,
  isCardInnerAccordionSection,
} from '../../../constants/ResourceSupport';
import { useTranslation } from '../../../hooks/useKeyedTranslation';
import ResourceCards from '../ResourceCards/ResourceCards';
import { StyledSubContentTypography } from '../StyledSubContentTypography';
import InnerAccordion from './InnerAccordion';

interface ContentInnerAccordionProps {
  section: InnerAccordionSection;
}

export const ContentInnerAccordion = ({
  section,
}: ContentInnerAccordionProps) => {
  const { t } = useTranslation();

  let children: ReactNode;
  let title: string;

  if (isCardInnerAccordionSection(section)) {
    children = <ResourceCards resources={section.content} />;
    title = t(section.title);
  } else {
    children = (
      <StyledSubContentTypography>
        {t(section.content)}
      </StyledSubContentTypography>
    );
    title = t(section.title);
  }

  return (
    <InnerAccordion summaryText={title} fullWidth={true}>
      {children}
    </InnerAccordion>
  );
};
