import { IPhoneNumber } from '../contexts/DataContext';

const PHONE_REGEX = /(\d{0,1})(\d{3})(\d{3})(\d{4})/;

export function formatPhone(phone: IPhoneNumber): string {
  const phoneComponents = phone.number.match(PHONE_REGEX);
  let phoneNumber = phone.number;
  if (phoneComponents) {
    const [, countryCode, areaCode, telephonePrefix, lineNumber] =
      phoneComponents;
    phoneNumber = `${
      // &nbsp;                     v
      countryCode ? `+${countryCode} ` : ''
      // &nbsp;   v
    }(${areaCode}) ${telephonePrefix}-${lineNumber}`;
  }
  const extension = phone.extension ? `×${phone.extension}` : '';
  return phoneNumber + extension;
}
