import { Box, Button } from '@mui/material';
import { useContext } from 'react';
import { Section } from '../../constants/ResourceSupport';
import DataContext from '../../contexts/DataContext';
import { useConnectNowButtonHandlers } from '../../hooks/useConnectNowButtonHandlers';
import { useTranslation } from '../../hooks/useKeyedTranslation';
import { keyGenerator } from '../../utils';
import RAAMClinicReconnectionPrompt from '../Dialog/RAAMClinicReconnectionPrompt';
import { ClinicInnerAccordion } from './InnerAccordion/ClinicInnerAccordion';
import { ContentInnerAccordion } from './InnerAccordion/ContentInnerAccordion';
import { StyledSubContentTypography } from './StyledSubContentTypography';
import { StyledSubHeadingTypography } from './StyledSubHeadingTypography';

interface ResourceSectionProps {
  section: Section;
}

export const ResourceSection = ({ section }: ResourceSectionProps) => {
  const { t } = useTranslation();
  const [isModalOpen, connectNowHandler, closeModal] =
    useConnectNowButtonHandlers();
  const { clinics } = useContext(DataContext);

  return (
    <>
      <Box>
        <StyledSubHeadingTypography>
          {t(section.title)}
        </StyledSubHeadingTypography>
        <StyledSubContentTypography>
          {t(section.content)}
        </StyledSubContentTypography>
      </Box>
      {section.display &&
        ('ConnectNow' === section.display ? (
          <Box display="flex" justifyContent="center">
            <Button
              onClick={connectNowHandler}
              title={t('landing.cards.connect_now.button.title')}
              aria-label={t('landing.cards.connect_now.button.aria_label')}
              variant="outlined"
            >
              {t('landing.cards.connect_now.button.label')}
            </Button>
          </Box>
        ) : (
          <Box px={1.5}>
            {'Clinics' === section.display
              ? clinics.map((value) => (
                  <ClinicInnerAccordion clinic={value} key={value.dfdLink} />
                ))
              : section.display.map((innerSection, index) => (
                  <ContentInnerAccordion
                    section={innerSection}
                    key={keyGenerator(index)}
                  />
                ))}
          </Box>
        ))}
      <RAAMClinicReconnectionPrompt
        open={isModalOpen}
        closeDialog={closeModal}
      />
    </>
  );
};
