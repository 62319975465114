import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { memo, useCallback, useContext } from 'react';
import DataContext from '../../contexts/DataContext';
import { useConnectNowContext } from '../../hooks/useConnectNowContext';
import useDirtyTextReader from '../../hooks/useDirtyTextReader';
import { I18nAccess, useTranslation } from '../../hooks/useKeyedTranslation';

const StyledStack = styled(Stack)(() => ({
  height: '100%',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.8)',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '16px',
  padding: '1.5em',
  flexGrow: 1,
  justifyContent: 'space-between',
  maxHeight: '300px',
  backdropFilter: 'blur(6.7957px)',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  fontStyle: 'italic',
  lineHeight: '1.1875rem',
  color: theme.palette.primary.main,
}));

export interface SelectClinicProps {
  text: I18nAccess;
  label: I18nAccess;
}

function SelectClinic({ text, label }: SelectClinicProps) {
  const { t } = useTranslation();
  const { state, onSelectClinicHandler, onContinueClinicHandler } =
    useConnectNowContext();

  const onSelectHandler = useCallback(
    (event: SelectChangeEvent) => {
      const selectedOption = event.target.value;
      onSelectClinicHandler(selectedOption);
    },
    [onSelectClinicHandler]
  );

  const { clinics } = useContext(DataContext);
  const dirtyTextReader = useDirtyTextReader();

  return (
    <StyledStack spacing={3}>
      <Stack spacing={2} maxWidth="100%">
        <StyledTypography>{t(text)}</StyledTypography>
        <FormControl fullWidth>
          <InputLabel id="select-question">{t(label)}</InputLabel>
          <Select
            labelId="select-question"
            value={state.selectedOption}
            onChange={onSelectHandler}
            label={t(label)}
          >
            {clinics.map((option) => (
              <MenuItem
                value={dirtyTextReader(option.name)}
                key={option.dfdLink}
              >
                {dirtyTextReader(option.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Box display="flex" justifyContent="center" width="100%">
        <Button
          role="button"
          type="button"
          variant="contained"
          color="primary"
          title={t('connect_now.continue_button.title')}
          aria-label={t('connect_now.continue_button.aria_label')}
          onClick={() => onContinueClinicHandler(clinics)}
          disabled={!state.selectedOption}
        >
          {t('connect_now.continue_button.label')}
        </Button>
      </Box>
    </StyledStack>
  );
}

export default memo(SelectClinic);
