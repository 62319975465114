import { Box, Grid, Typography } from '@mui/material';
import { PALE_GRAY } from '../../../constants/colors';
import useDirtyTextReader from '../../../hooks/useDirtyTextReader';
import { ClinicLocation, OFDClinic, generateDFDClinic } from '../../../utils';
import ClinicDetails from '../../FindClinic/ClinicList/ClinicDetails';

interface ClinicLocationCardProps {
  clinic: OFDClinic;
  location: ClinicLocation;
}

export const ClinicLocationCard = ({
  clinic,
  location,
}: ClinicLocationCardProps) => {
  const dirtyTextReader = useDirtyTextReader();

  return (
    <Grid item xs={12} md={6}>
      <Box
        sx={{
          borderRadius: '0.5rem',
          border: `1px solid ${PALE_GRAY}`,
          height: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: '700',
            lineHeight: '1.125rem',
            paddingTop: '1rem',
            paddingLeft: '1rem',
          }}
        >
          {dirtyTextReader(location.name)}
        </Typography>
        <ClinicDetails
          clinic={generateDFDClinic(clinic, location)}
          rows={[
            'description',
            'hours',
            'telephone',
            'website',
            'dfdLink',
            'address',
          ]}
          padding="0.5rem 0.5rem 0.5rem 0.75rem"
        />
      </Box>
    </Grid>
  );
};
