import { Stack, styled } from '@mui/material';

export const ResponsiveCardStack = styled(Stack)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.8)',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '16px',
  padding: '1.5em',
  paddingLeft: '3.75em',
  paddingRight: '3.75em',
  paddingBottom: '2em',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));
