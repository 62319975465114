import { Box, styled } from '@mui/material';

export const ResponsiveRowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));
