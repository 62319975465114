import { Grid } from '@mui/material';
import useDirtyTextReader from '../../../hooks/useDirtyTextReader';
import { useTranslation } from '../../../hooks/useKeyedTranslation';
import { OFDClinic } from '../../../utils';
import { StyledCardText } from '../ResourceCards/StyledCardText';
import { ClinicLocationCard } from './ClinicLocationCard';
import InnerAccordion from './InnerAccordion';

interface ClinicInnerAccordionProps {
  clinic: OFDClinic;
}

export const ClinicInnerAccordion = ({ clinic }: ClinicInnerAccordionProps) => {
  const { t } = useTranslation();
  const dirtyTextReader = useDirtyTextReader();

  return (
    <InnerAccordion summaryText={dirtyTextReader(clinic.name)} fullWidth={true}>
      <StyledCardText>{t('lorem.long')}</StyledCardText>
      <Grid container spacing={2} paddingTop="1rem" alignContent="stretch">
        {clinic.locations.map((location, index) => (
          <ClinicLocationCard clinic={clinic} location={location} key={index} />
        ))}
      </Grid>
    </InnerAccordion>
  );
};
