import _ from 'lodash';
import { IAltService, IDirtyText, IPhoneNumber } from '../contexts/DataContext';

export interface ClinicDataType extends google.maps.LatLngLiteral {
  name: IDirtyText;
  description?: string;
  hours: IDirtyText;
  walkInHours?: IDirtyText;
  // Some clinics missing website
  website?: string;
  address: IDirtyText;
  telephone: IPhoneNumber[];
}

type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
type Hours = `${0 | 1}${Digit}` | `2${0 | 1 | 2 | 3}`;
type Minutes = `${0 | 1 | 2 | 3 | 4 | 5}${Digit}`;
export type TimeString = `${Hours}:${Minutes}`;

export interface DayHours {
  // Could be replaced by `string` but this is more fun :)
  openHour: TimeString;
  closeHour: TimeString;
  open: boolean;
}

export interface VirtualClinic {
  dfdLink: string;
}

export type WeeklyHours = [
  DayHours,
  DayHours,
  DayHours,
  DayHours,
  DayHours,
  DayHours,
  DayHours
];

export interface DFDClinicDataType extends ClinicLocation, VirtualClinic {
  altServices: IAltService[];
}

export const isDFDClinic = (
  clinic: ClinicDataType
): clinic is DFDClinicDataType => {
  return _.has(clinic, 'dfdLink');
};

// export type ClinicLocation = Omit<DFDClinicDataType, 'dfdLink' | 'altServices'>;
export interface ClinicLocation extends ClinicDataType {
  dfdHours: WeeklyHours;
  /** If `breakHours[day].open`, then the clinic is actually closed during that period. */
  breakHours: WeeklyHours;
}

export interface OFDClinic extends VirtualClinic {
  name: IDirtyText | {};
  altServices: IAltService[];
  locations: ClinicLocation[];
}

export const generateDFDClinic = (
  clinic: OFDClinic,
  location: ClinicLocation | number
): DFDClinicDataType => {
  if (typeof location === 'number') {
    location = clinic.locations[location];
  }
  return {
    dfdLink: clinic.dfdLink,
    altServices: clinic.altServices,
    ...location,
  };
};

export const NON_DFD_CLINICS: ClinicDataType[] = [
  {
    name: { en: 'Aegis Health Group' },
    lat: 42.3025884,
    lng: -82.9961414,
    hours: {
      en: 'Walk-in and scheduled appointments: Mon-Fri 8am-5pm. Drop-in and scheduled virtual care also available during clinic hours.',
    },
    website: 'https://www.aegishealth.ca/',
    address: { en: '1883 Turner Road, Windsor, ON, Canada' },
    telephone: [{ number: '226-946-1000' }],
  },
  {
    name: { en: 'Aegis Health Group' },
    lat: 42.307277,
    lng: -83.032823,
    hours: {
      en: 'Walk-in and scheduled appointments: Mon-Fri 8am-5pm. Drop-in and scheduled virtual care also available during clinic hours.',
    },
    website: 'https://www.aegishealth.ca/',
    address: { en: '1083 Ouellette Avenue, Windsor, ON, Canada' },
    telephone: [{ number: '226-946-1000' }],
  },
  {
    name: { en: 'Alliance Centre – West Nipissing General Hospital' },
    lat: 46.3722287,
    lng: -79.9162421,
    hours: {
      en: 'Walk-in: Mon, Tues, Thurs, Fri 8:30am-4:30pm, Wed 8:30am-7pm. Scheduled appointments Tues 9am-12noon and Thurs 2-4:30pm.',
    },
    website: 'https://www.wngh.ca/',
    address: {
      en: 'West Nipissing General Hospital, 172 Ethel Street, Unit 4, Sturgeon Falls, ON Canada',
    },
    telephone: [{ number: '705-753-2271' }],
  },
  {
    name: { en: 'Anishnawbe Health Toronto' },
    lat: 43.6607323,
    lng: -79.373192,
    hours: {
      en: 'Walk-in, virtual and scheduled appointments. Mon-Fri 9am-5pm.',
    },
    website: 'https://aht.ca/service/substance-abuse-supports/',
    address: { en: '179 Gerrard St. E, Toronto.' },
    telephone: [{ number: '416-657-0379', extension: '234' }],
  },
  {
    name: { en: 'Bluewater Methadone Clinic – Sarnia' },
    lat: 42.9712548,
    lng: -82.4056469,
    hours: {
      en: 'Walk in and schedule appointments: Mon 9am-5pm, Tues and Fri 9am-4pm, Thurs 9am-6pm. (Walk-ins welcome until one hour before clinic closes.) Appointments are usually given for the next day the clinic is open. Virtual/phone: Mon 9am-6pm, Tues and Fri 9am-5pm, Thurs 9am-7pm.',
    },
    website: 'https://bluewatermethadoneclinic.ca/raam-clinic/',
    address: { en: '118 Victoria St. N, Sarnia' },
    telephone: [{ number: '519-337-4357' }],
  },
  {
    name: {
      en: 'Brampton – CMHA Peel Dufferin William G. Davis Centre for Families',
    },
    lat: 43.7030285,
    lng: -79.7252208,
    hours: {
      en: 'In-person and virtual drop-in hours: Tues 12-2pm and 4:30-6:30pm. NEW Sat clinic: 10am-1pm. Scheduled in-person and virtual appointments: Mon-Fri 8:30am-pm. RAAM virtual care appointments are organized with the client via direct phone call during the regular clinical hours. Clients who have not been registered are required to complete an application intake process and/or assessment in person which is administered by the NP or RN on site.',
    },
    website:
      'https://cmhapeeldufferin.ca/programs/rapid-access-addiction-medicine-clinic-raam/',
    address: { en: '60 West Dr., Unit 106, Brampton' },
    telephone: [{ number: '905-451-2123' }],
  },
  {
    name: { en: 'Cambridge RAAM Clinic' },
    lat: 43.3594619,
    lng: -80.3152144,
    hours: {
      en: 'Walk-in and scheduled appointments Monday and Friday 9:00am-3:00pm.',
    },
    website:
      'https://houseoffriendship.org/addiction/rapid-access-addiction-medicine-clinic/',
    address: { en: '13 Water St. N (Rear entrance), Cambridge.' },
    telephone: [
      { number: '519-957-5000' },
      { number: '519-742-8327', extension: '562' },
    ],
  },
  {
    name: { en: 'Chatham-Kent Health Alliance RAAM Clinic' },
    lat: 42.4050582,
    lng: -82.1936363,
    hours: {
      en: 'Walk-ins and scheduled appointments: Mon, Wed, Fri 12-4pm. Scheduled appointments only: Thurs 12-4pm. Virtual care available during opening hours.',
    },
    website: 'https://www.ckha.on.ca/raam',
    address: { en: '80 Grand Ave West, Zone F, Level 1, Chatham, ON N7M 5L9' },
    telephone: [{ number: '519-352-6400', extension: '6740' }],
  },
  {
    name: { en: 'CKHA RAAM Clinic, Wallaceburg' },
    lat: 42.5991912,
    lng: -82.3668235,
    hours: { en: 'Drop-in hours: Tues 12-4pm.' },
    website: 'https://www.ckha.on.ca/raam/',
    address: { en: '325 Margaret Ave, Rm 102, Wallaceburg, ON N8A 2A7' },
    telephone: [{ number: '519-352-6400', extension: '8311' }],
  },
  {
    name: { en: 'Clinic 217' },
    lat: 42.779094,
    lng: -81.1949838,
    hours: {
      en: 'Walk-in and scheduled appointments: Mon 8:30-11:30am, 1-3:30pm, 3:45-7pm; Tues, Thurs 8:30-11:30am, Fri 8:30-11:30 and 1-3:30pm. New patient assessments and starts: Mon, Tues, Thurs and Fri 10am-1pm. Phone appointments available upon request.',
    },
    website: 'https://www.southwesthealthline.ca/displayservice.aspx?id=185620',
    address: { en: '475 Talbot St, St. Thomas.' },
    telephone: [{ number: '519-631-9040' }],
  },
  {
    name: { en: 'Cochrane District RAAM clinic' },
    lat: 48.4735837,
    lng: -81.3253813,
    hours: {
      en: 'Walk-in, Virtual and scheduled appointments: Mon, Wed 2-5pm, Fri 1-5pm. Walk-ins welcome based on staff availability.',
    },
    website: 'https://www.scasinc.ca/', // Temporary info link, offers redirect to 'https://cmhact.ca/'
    address: { en: '330 2nd Ave., Timmins, ON P4N 8A4' },
    telephone: [{ number: '705-531-7226' }],
  },
  {
    name: { en: 'Cochrane District RAAM clinic - Satellite location' },
    lat: 48.76941806544684,
    lng: -80.6846809729342,
    hours: {
      en: 'Virtual and scheduled appointments Fri 1-5pm. Walk-ins welcome based on staff availability.',
    },
    website: 'https://www.scasinc.ca/', // Temporary info link, offers redirect to 'https://cmhact.ca/'
    address: { en: '58A Anson Drive, Iroquois Falls' },
    telephone: [{ number: '705-531-7226' }],
  },
  {
    name: {
      en: 'COMPASS (Concurrent Outpatient Medical & Psychosocial Addiction Support Service)',
    },
    lat: 43.6433768,
    lng: -79.4190366,
    hours: { en: 'Walk-in services: Mon-Fri 10am-noon.' },
    website: 'https://www.camh.ca/en/your-care/programs-and-services/compass',
    address: { en: 'CAMH, Bell Gateway Building, 3rd Floor, 100 Stokes St' },
    telephone: [{ number: '416-535-8501' }],
  },
  {
    name: { en: 'Comprehensive Treatment Clinic' },
    lat: 43.6815219,
    lng: -79.3334635,
    hours: {
      en: 'Walk-ins: Mon 1-3pm, Tues and Fri 12-2pm. Please call ahead if possible before walking in. Scheduled appointments: Thurs 1-3pm. Phone and virtual care appointments available upon request.',
    },
    website: 'https://comprehensivetreatmentclinic.com/',
    address: { en: '1154 Danforth Avenue, Toronto, ON M4J 1M3, Canada' },
    telephone: [{ number: '647-490-4008' }],
  },
  {
    name: { en: 'Dilico RAAM Clinic' },
    lat: 48.3542248,
    lng: -89.2612659,
    hours: {
      en: 'Drop-in: Mon, Fri, and every second Tues 9:30am-11:30am. Virtual drop-in and booked appointments: Mon, Tues, Fri 9:30am-11:30am.',
    },
    website:
      'https://www.tbaycounselling.com/addiction-mental-health/rapid-access-addiction-medicine-clinic/',
    address: {
      en: '200 Anemki Place, Fort William First Nation, Thunder Bay.',
    },
    telephone: [{ number: '807-626-8478' }],
  },
  {
    name: { en: 'Duke St RAAM Clinic' },
    lat: 43.4526429,
    lng: -80.4929146,
    hours: { en: 'Walk-in hours Mon-Fri 9am-3pm.' },
    website:
      'https://houseoffriendship.org/addiction/rapid-access-addiction-medicine-clinic/',
    address: { en: '150 Duke Street West, Kitchener, ON, Canada' },
    telephone: [
      { number: '519-957-5000' },
      { number: '519-742-8327', extension: '562' },
    ],
  },
  {
    name: { en: 'EYRND Mental Health and Addiction Wellness Centre' },
    lat: 43.8792391,
    lng: -79.3135941,
    hours: { en: 'Scheduled Appointments: Tues 1-4pm.' },
    website:
      'https://www.eyrnd.ca/mental-health-and-addictions-wellness-centre',
    address: { en: '9249 Kennedy Rd unit 101, Unionville, ON, Canada' },
    telephone: [{ number: '905-758-2279' }],
  },
  {
    name: { en: 'Englehart & District Family Health Team – RAAM Clinic' },
    lat: 47.8226775,
    lng: -79.8792617,
    hours: {
      en: 'Walk-in, virtual and scheduled appointments. Mon-Fri 9:00-3:00pm. RAAM services are provided within our Family Health Team. Our NP (provider) does a quick assessment of walk-ins, after which the client is scheduled for an in-person appointment within 48 hours. Clients who call to make an appointment are seen within 48 hours. New clients have in-person visits initially and then follow up appointments if suitable.',
    },
    website: 'https://edfht.com/',
    address: { en: '63 5th Street, Englehart, ON, Canada' },
    telephone: [{ number: '705-544-2321' }],
  },
  {
    name: { en: 'GBHS RAAM Clinic' },
    lat: 44.5600212,
    lng: -80.9255921,
    hours: {
      en: 'Booked appointments (in person or phone) only: Mon and Fri 9am-noon, Tues 8am-noon, Wed and Thurs 1pm-4pm.',
    },
    website: 'https://www.gbhs.on.ca/mental-health-addiction-services/',
    address: { en: '495 9th Ave. E, Owen Sound' },
    telephone: [{ number: '519-376-5666', extension: '2' }],
  },
  {
    name: { en: 'Grand River Hospital RAAM Clinic' },
    lat: 43.4570781,
    lng: -80.5102857,
    hours: { en: 'Walk-in hours Mon-Fri 9am-3pm.' },
    website:
      'https://houseoffriendship.org/addiction/rapid-access-addiction-medicine-clinic/',
    address: { en: '850 King Street West, Kitchener, ON, Canada' },
    telephone: [
      { number: '519-957-5000' },
      { number: '519-742-8327', extension: '562' },
    ],
  },
  {
    name: { en: 'Halton & Mississauga RAAM Clinic' },
    lat: 43.405081554400844,
    lng: -79.8076666241141,
    hours: {
      en: 'Walk-in and scheduled appointments: Mon 9am-3:30pm, Tues 11:30am-6pm, Wed 9am-6pm, Thurs 9am-4pm, Fri 10am-3pm. New patients should arrive at least one hour before clinic closes. Virtual care also available.',
    },
    website: 'https://hmraam.ca/',
    address: { en: '2500 Appleby Line, Unit D10, Burlington, ON' },
    telephone: [{ number: '1-888-388-RAAM' }],
  },
  {
    name: { en: 'Halton & Mississauga RAAM Clinic' },
    lat: 43.6492247,
    lng: -79.9266377,
    hours: {
      en: 'Walk-in and scheduled appointments: Mon 9am-3:30pm, Tues 11:30am-6:30pm, Wed 9am-6pm, Thurs 9am-4pm, Fri 10am-3pm. New patients should arrive at least one hour before clinic closes. Virtual care also available.',
    },
    website: 'https://hmraam.ca/',
    address: {
      en: 'Halton RAAM and Methadone Clinic – Georgetown, 118 Mill Street, Georgetown, ON, Canada',
    },
    telephone: [{ number: '1-888-388-RAAM' }],
  },
  {
    name: { en: 'Halton & Mississauga RAAM Clinic' },
    lat: 43.5175824,
    lng: -79.8776902,
    hours: {
      en: 'Walk-in and scheduled appointments: Mon 9am-3:30pm, Tues 11:30am-6pm, Wed 9am-6pm, Thurs 9am-4pm, Fri 10am-3pm. New patients should arrive at least one hour before clinic closes. Virtual care also available.',
    },
    website: 'https://hmraam.ca/',
    address: { en: '400 Main Street E, Unit 112, Milton' },
    telephone: [{ number: '1-888-388-RAAM' }],
  },
  {
    name: { en: 'Halton & Mississauga RAAM Clinic' },
    lat: 43.4787986,
    lng: -79.6601658,
    hours: {
      en: 'Walk-in and scheduled appointments: Mon 9am-3:30pm, Tues 11:30am-6:30pm, Wed 9am-6pm, Thurs 9am-4pm, Fri 10am-3pm. New patients should arrive at least one hour before clinic closes. Virtual care also available.',
    },
    website: 'https://hmraam.ca/',
    address: { en: '1525 Cornwall Road, Oakville, ON, Canada' },
    telephone: [{ number: '1-888-388-RAAM' }],
  },
  {
    name: { en: 'Halton & Mississauga RAAM Clinic' },
    lat: 43.4462308,
    lng: -79.6865702,
    hours: {
      en: 'Walk-in and scheduled appointments: Mon 9am-3:30pm, Tues 11:30am-6pm, Wed 9:30am-6pm, Thurs 9:30am-4pm, Fri 10am-3pm. New patients should arrive at least one hour before clinic closes. Virtual care also available.',
    },
    website: 'https://hmraam.ca/',
    address: { en: '496 Kerr Street, Oakville, ON, Canada' },
    telephone: [{ number: '1-888-388-RAAM' }],
  },
  {
    name: { en: 'Halton & Mississauga RAAM Clinic' },
    lat: 43.5291056,
    lng: -79.639026,
    hours: {
      en: 'Walk-in and scheduled appointments: Mon 9am-3:30pm, Tues 11:30am-6:30pm, Wed 9:30am-6pm, Thurs 9:30am-4pm, Fri 10am-3pm. New patients should arrive at least one hour before clinic closes. Virtual care also available.',
    },
    website: 'https://hmraam.ca/',
    address: { en: '1603 Clarkson Road North, Mississauga, ON, Canada' },
    telephone: [{ number: '1-888-388-RAAM' }],
  },
  {
    name: { en: 'Kapuskasing RAAM Clinic' },
    lat: 49.4149643,
    lng: -82.4197823,
    hours: {
      en: 'Drop-in hours: Mon and Thurs 1:00pm-3:30pm. Phone/virtual: same.',
    },
    website: 'https://www.addictionservicestoxicomanie.com/',
    address: { en: '27 Kolb Avenue, Kapuskasing ON P5N 1G2' },
    telephone: [{ number: '705-335-8408' }],
  },
  {
    name: { en: 'Kenora RAAM Clinic' },
    lat: 49.7662803,
    lng: -94.4886965,
    hours: { en: 'Walk-in and scheduled appointments: Mon-Fri 8am-4pm.' },
    website: 'http://www.lwdh.on.ca/', // https not available
    address: { en: 'Suite 5 – 308 Second Street South, kenora' },
    telephone: [{ number: '807-464-6397' }],
  },
  {
    name: { en: 'Kirkland Lake RAAM Clinic' },
    lat: 48.149927,
    lng: -80.0328673,
    hours: { en: 'Scheduled appointments Tues 9-11am.' },
    website: 'https://cmhact.ca/',
    address: { en: '7 Kirkland St W, Kirkland Lake, ON, P2N 1N9' }, // It's actually 5 Kirkland, but Google Maps doesn't think that building exists
    telephone: [{ number: '705-567-9596' }],
  },
  {
    name: { en: 'Leamington Clinic' },
    description: '24/7 emergency line for urgent care: 519-257-0981',
    lat: 42.0540881,
    lng: -82.6011275,
    hours: {
      en: 'Walk-in and scheduled appointments: Thurs 1-4:30pm. Virtual care available same times.',
    },
    website: 'https://www.roseraam.ca/',
    address: { en: '15 John St, Leamington.' },
    telephone: [{ number: '519-398-9199' }],
  },
  {
    name: { en: 'Marathon Family Health Team RAAM Clinic' },
    lat: 48.7180291,
    lng: -86.3762527,
    hours: {
      en: 'Walk-in, virtual and scheduled appointments. Tues and Thurs 12:20-4:30PM.',
    },
    website: 'https://mfht.org/',
    address: { en: '22 Peninsula Road, Marathon, ON, Canada' },
    telephone: [{ number: '807-229-3243' }],
  },
  {
    name: { en: 'Maternity Centre of Hamilton (PROSPR program)' },
    lat: 43.2569357,
    lng: -79.8735212,
    hours: {
      en: 'Walk-in and scheduled appointments: Wed 1-4pm. Virtual care available only in exceptional circumstances.',
    },
    website: 'https://mch.mcmaster.ca/services/prospr/',
    address: {
      en: '100 Main St. W, 3rd Floor (For pregnant patients), Hamilton.',
    },
    telephone: [{ number: '905-528-5553' }],
  },
  {
    name: { en: 'Michael Garron Hospital' },
    description: 'Email: raamclinic@tehn.ca',
    lat: 43.6898713,
    lng: -79.3248582,
    hours: {
      en: 'Walk-in (in person and virtual): Tues, Thurs, Fri 1-3pm. Scheduled appointments: Mon, Tues 8am-3pm; Wed 8am-4pm; Thurs, Fri 1-3pm.',
    },
    website:
      'https://www.tehn.ca/programs-services/mental-health-addiction/addiction-services/rapid-access-addiction-medicine-clinic',
    address: {
      en: '825 Coxwell Ave., 1st Floor (Admitting/Registration), Toronto.',
    },
    telephone: [{ number: '416-469-6580', extension: '2517' }],
  },
  {
    name: { en: 'Muskoka RAAM Clinic' },
    lat: 45.043942,
    lng: -79.315419,
    hours: {
      en: 'Walk-in and scheduled appointments: Mon-Fri 8:30am-4:30pm. Scheduled virtual care: M, T, F 9am-12pm and W, Th 1-4pm.',
    },
    website:
      'https://mps.cmha.ca/programs-services/raam-rapid-access-addiction-medicine/',
    address: { en: '173 Manitoba St., Suite 202, Bracebridge.' },
    telephone: [{ number: '705-645-2262' }, { number: '1-800-245-5036' }],
  },
  {
    name: { en: 'Niagara Health RAAM Clinic' },
    lat: 43.1677914,
    lng: -79.2332201,
    hours: { en: 'Drop-in: Mon 9am-4pm.' },
    website: 'https://www.niagarahealth.on.ca/site/addictionrecoveryservices',
    address: { en: '264 Welland Avenue, St. Catharines, ON, Canada' },
    telephone: [{ number: '905-378-4647' }],
  },
  {
    name: {
      en: 'Niagara Health RAAM – Community Addiction Services of Niagara (CASON) Walk in Clinic',
    },
    lat: 43.1590255,
    lng: -79.2452975,
    hours: { en: 'Drop-in: Tues 12-4pm.' },
    website: 'https://www.niagarahealth.on.ca/site/addictionrecoveryservices',
    address: { en: '60 James Street, Suite 404, St. Catharines, ON L2R 7E7' },
    telephone: [{ number: '905-378-4647', extension: '49463' }],
  },
  {
    name: { en: 'Niagara Health RAAM – Niagara Falls Hospital' },
    lat: 43.0939334,
    lng: -79.0895715,
    hours: { en: 'No walk-in hours. Scheduled appointments: Mon-Fri 8am-4pm.' },
    website: 'https://www.niagarahealth.on.ca/site/addictionrecoveryservices',
    address: { en: '5546 Portage Road, Niagara Falls, ON L2E 6X2' },
    telephone: [{ number: '905-378-4647', extension: '49463' }],
  },
  {
    name: { en: 'Niagara Health RAAM – St. Catharines' },
    lat: 43.153663,
    lng: -79.2785004,
    hours: { en: 'No drop-in hours. Booked appointments: Mon-Fri, 8am-4pm.' },
    website: 'https://www.niagarahealth.on.ca/site/addictionrecoveryservices',
    address: { en: '1200 Fourth Ave., St. Catharines, ON L2S 0A9' },
    telephone: [{ number: '905-378-4647', extension: '49463' }],
  },
  {
    name: { en: 'Niagara Health RAAM – Welland Hospital site' },
    lat: 42.9807228,
    lng: -79.2512236,
    hours: { en: 'No drop-in hours. Booked appointments Mon-Fri, 8am-4pm.' },
    website: 'https://www.niagarahealth.on.ca/site/addictionrecoveryservices',
    address: { en: '65 Third Street, Welland, ON L3B 4W6' },
    telephone: [{ number: '905-378-4647', extension: '49463' }],
  },
  {
    name: { en: 'North Bay Regional Health Centre RAAM Clinic' },
    lat: 46.3153379,
    lng: -79.454043,
    hours: {
      en: 'Walk-in, virtual and scheduled appointments Mon-Fri 8:30am-4pm. Scheduled appointments with physician Mon 2-5pm.',
    },
    website: 'https://www.connexontario.ca/Program-Details?ProgramId=35577',
    address: { en: '120 King St. West, North Bay, ON' },
    telephone: [{ number: '1-833-434-7800' }],
  },
  {
    name: { en: 'North Simcoe Muskoka RAAM Clinic' },
    lat: 44.3919901,
    lng: -79.6998006,
    hours: {
      en: 'In-person and virtual drop in hours: Mon-Sat 8:30 am-3:30 pm. Scheduled in-person, virtual or phone appointments: Mon-Sat 8:30 am – 3:30 pm.',
    },
    website:
      'https://www.rvh.on.ca/areas-of-care/mental-health-and-addictions/adult-addiction-services/',
    address: { en: '70 Wellington St. W, Lower Level, Barrie.' },
    telephone: [{ number: '1-833-797-3095' }, { number: '705-797-3095' }],
  },
  {
    name: { en: 'North Simcoe Muskoka RAAM Clinic' },
    lat: 44.6053465,
    lng: -79.411273,
    hours: {
      en: 'Scheduled virtual and in-person appointments: Mon-Fri 8:30am-3:30pm.',
    },
    website:
      'https://www.rvh.on.ca/areas-of-care/mental-health-and-addictions/adult-addiction-services/',
    address: {
      en: '169 Front St. South, 1st Floor (Use side entrance), Orillia.',
    },
    telephone: [{ number: '1-833-797-3095' }, { number: '705-797-3095' }],
  },
  {
    name: { en: 'North Simcoe Muskoka Regional RAAM Clinic' },
    lat: 44.4685371,
    lng: -80.0758862,
    hours: {
      en: 'Drop-in, virtual and scheduled appointments: Mon-Fri 8:30am-3:30pm.',
    },
    website:
      'https://www.rvh.on.ca/areas-of-care/mental-health-and-addictions/adult-addiction-services/',
    address: {
      en: 'South Georgian Bay Community Health Centre, 14 Ramblewood Dr., Unit 202, 2nd Floor, Wasaga Beach.',
    },
    telephone: [{ number: '1-833-797-3095' }],
  },
  {
    name: {
      en: 'North Simcoe Muskoka Regional RAAM – Chigamik Community Health Centre',
    },
    lat: 44.7557393,
    lng: -79.8829725,
    hours: {
      en: 'Drop-in (in person and virtual), and scheduled appointments: Mon-Fri 8:30am-3:30pm.',
    },
    website:
      'https://www.rvh.on.ca/areas-of-care/mental-health-and-addictions/adult-addiction-services/',
    address: { en: '287 Bayshore Dr., 2nd Floor, Midland.' },
    telephone: [{ number: '1-833-797-3095' }],
  },
  {
    name: { en: 'Oasis RAAM program' },
    lat: 45.4293799,
    lng: -75.6835995,
    hours: { en: 'Drop-in and appointments: Mon-Fri 9am-2pm.' },
    website: 'https://www.sandyhillchc.on.ca/',
    address: { en: '221 Nelson St, Ottawa, Ontario K1N 1C7, Canada' },
    telephone: [{ number: '613-569-3488', extension: '2106' }],
  },
  {
    name: { en: 'Oxford County RAAM Clinic' },
    lat: 43.1287671,
    lng: -80.7564126,
    hours: {
      en: 'Walk-ins and scheduled appointments: Mon 9-11am and Tues 1-3pm. Phone appointments Mon-Fri 9am-3pm.',
    },
    website: 'https://www.oxfordraam.ca/',
    address: { en: '35 Metcalf St., 2nd floor, Woodstock' },
    telephone: [{ number: '519-539-1111' }],
  },
  {
    name: { en: 'Oxford County RAAM Clinic - Satellite location' },
    lat: 43.03810493990238,
    lng: -80.88166362059052,
    hours: {
      en: 'Phone appointments Mon-Fri 9am-3pm. Scheduled appointments Wed 9-11am.',
    },
    website: 'https://www.oxfordraam.ca/',
    address: { en: '19 King St. East, Ingersoll' },
    telephone: [{ number: '519-539-1111' }],
  },
  {
    name: { en: 'PAARC – CMHA Peel Dufferin' },
    lat: 43.6385565,
    lng: -79.6259097,
    hours: {
      en: 'Drop-in hours: Tues 10am-1pm. Virtual/phone: Mon-Fri 8:30am-4pm.',
    },
    website:
      'https://cmhapeeldufferin.ca/programs/rapid-access-addiction-medicine-clinic-raam/',
    address: {
      en: 'Peel Addiction Assessment and Referral Centre (PAARC), 5170 Dixie Road, Suite 302, Mississauga, ON L4W 1E3',
    },
    telephone: [{ number: '905-451-2123', extension: '2' }],
  },
  {
    name: { en: 'Parry Sound RAAM Clinic' },
    lat: 45.34053506757901,
    lng: -80.01691193480156,
    hours: {
      en: 'Scheduled appointments: Mon 10am-6pm and Thurs 8:30am-4pm. No drop-in hours.',
    },
    website: 'https://www.wpshc.com/',
    address: {
      en: 'West Parry Sound Health Centre, 1st floor Mental Health Hub,  6 Albert St. Parry Sound, ON',
    },
    telephone: [{ number: '705-746-4540', extension: '1339' }],
  },
  {
    name: { en: 'Peel Youth Village – CMHA Peel Dufferin' },
    lat: 43.6053746,
    lng: -79.6449912,
    hours: {
      en: 'Walk-in Wed 12-2pm and 4:30-6:30pm. Virtual/phone appointments Mon-Fri 8:30am-4pm.',
    },
    website:
      'https://cmhapeeldufferin.ca/programs-services/rapid-access-addiction-medicine-clinic-raam/',
    address: { en: '99 Acorn Pl, Mississauga.' },
    telephone: [
      { number: '905-451-2123' },
      { number: '1-877-451-2123', extension: '2' },
    ],
  },
  {
    name: { en: 'Peterborough RAAM' },
    lat: 44.3040588,
    lng: -78.3197832,
    hours: {
      en: 'Walk-in and scheduled appointments: Mon, Wed, Fri 8am-12pm. Virtual appointments available: Lindsay – Thurs 12-4pm, Coburg – Tues 12:30-3:30pm.',
    },
    website:
      'https://www.prhc.on.ca/healthcare-services/mental-health-addictions/rapid-access-addiction-medicine-clinic/',
    address: { en: '360 George St N, Peterborough, ON, Canada' },
    telephone: [{ number: '705-749-9708' }],
  },
  {
    name: { en: 'RAAM Clinic Orangeville – CMHA Peel Dufferin' },
    lat: 43.9212702,
    lng: -80.0993215,
    hours: {
      en: 'Walk-in: Thurs 12-2pm and 4:30-6:30pm. Virtual drop in: Mon-Fri 8:30am-4:00pm. Scheduled in-person appointments Thurs 9-10am,1-4pm. RAAM virtual care appointments are organized with the client via direct phone call during the regular clinical hours stated for each clinic location. Clients who have not been registered are required to complete an application intake process and/or assessment in person which is administered by the NP or RN on site.',
    },
    website:
      'https://cmhapeeldufferin.ca/programs-services/rapid-access-addiction-medicine-clinic-raam/',
    address: { en: '1 Elizabeth St. Unit L-2, Orangeville.' },
    telephone: [{ number: '905-451-2123' }, { number: '1-877-451-2123' }],
  },
  {
    name: {
      en: 'RAAM Clinic – CMHA Thames Valley Addiction and Mental Health Services.',
    },
    lat: 42.9857105,
    lng: -81.2487554,
    hours: {
      en: 'Walk-ins Mon and Thurs 8:30am-3:30pm, Tues 8:30-11:30am, Wed 7:30-10:30am.',
    },
    website: 'https://cmhatv.ca/programs-services/raam-clinic/',
    address: {
      en: 'Addiction Services of Thames Valley, 200 Queens Ave. Suite 260',
    },
    telephone: [{ number: '519-673-3242', extension: '1281' }],
  },
  {
    name: { en: 'RAAM Clinic – Joseph Brant Hospital' },
    lat: 43.3168728,
    lng: -79.802229,
    hours: { en: 'Drop-in clinic Hours: Thurs 1-2 pm.' },
    website: 'https://www.josephbranthospital.ca/',
    address: {
      en: 'Joseph Brant Hospital, 1245 Lakeshore Rd, Burlington, ON, L7S 0A2',
    },
    telephone: [{ number: '905-632-3737', extension: '3401' }],
  },
  {
    name: { en: 'RAAM-Greenstone/Longlac Site' },
    lat: 49.7805055773698,
    lng: -86.53522066204181,
    hours: {
      en: 'Drop-in Hours: Mon-Thurs 9am-4pm, Fri 9am-12pm. Virtual/phone: Mon and Wed 1pm-4pm, Tues and Thurs 9am-4pm, Fri 9am-12pm.',
    },
    // Missing website
    address: { en: 'Dilico Anishinabek Family Care, 118 Forestry, Longlac' },
    telephone: [
      { number: '1-807-876-2271', extension: '132' },
      { number: '1-807-876-2271', extension: '148' },
    ],
  },
  {
    name: { en: 'Rainy River District RAAM' },
    lat: 48.6070103,
    lng: -93.3922754,
    hours: {
      en: 'Walk-in, virtual and scheduled appointments: Tues 1-4pm and Thurs 12-2pm.',
    },
    // Missing website
    address: {
      en: 'Riverside Health Care, 206 Victoria Avenue, Fort Frances, ON',
    },
    telephone: [
      { number: '1-807-274-2042', extension: '6230' },
      { number: '1-807-274-4807', extension: '1' },
    ],
  },
  {
    name: { en: 'Recovery Care Bells Corners' },
    lat: 45.3236011,
    lng: -75.8270094,
    hours: { en: 'Walk-in, same day services: Mon-Fri 8:00AM-4:00PM.' },
    website: 'https://recovery.care/',
    address: { en: '2006 Robertson Road, Nepean, ON, Canada' },
    telephone: [{ number: '613-903-6006' }],
  },
  {
    name: { en: 'Recovery Care Byward Market' },
    lat: 45.4288486,
    lng: -75.6851983,
    hours: { en: 'Walk-in, same day services: Mon-Fri 8:00AM-4:00PM.' },
    website: 'https://recovery.care/',
    address: { en: '306 Rideau Street, Ottawa, ON, Canada' },
    telephone: [{ number: '613-454-0321' }],
  },
  {
    name: { en: 'Recovery Care Vanier Clinic' },
    lat: 45.4335667,
    lng: -75.6687126,
    hours: {
      en: 'Walk-in, same day services: Mon-Fri 8am-4pm. Virtual care available same times.',
    },
    website: 'https://recovery.care',
    address: { en: '45 Montréal Road, Vanier, ON, Canada' },
    telephone: [{ number: '613-763-6882' }],
  },
  {
    name: { en: 'Rexdale – CMHA Peel Dufferin' },
    lat: 43.7465035,
    lng: -79.581531,
    hours: {
      en: 'Drop-in Hours: Mon 10am-1pm. Virtual/phone: Mon-Fri 8:30am-4pm.',
    },
    website:
      'https://cmhapeeldufferin.ca/programs/rapid-access-addiction-medicine-clinic-raam/',
    address: {
      en: 'Rexdale Community Hub 15-21 Panorama Crt., Etobicoke, ON M9V 4E3',
    },
    telephone: [{ number: '905-451-2123', extension: '2' }],
  },
  {
    name: { en: 'Roseville RAAM Clinic (City Centre)' },
    description: '24/7 emergency line for urgent care 519-257-0981',
    lat: 42.3169289,
    lng: -83.0296837,
    hours: {
      en: 'Walk-in and scheduled appointments: Mon 8:30am-4:30pm, Sat 8:30am-12noon. Virtual care available same times.',
    },
    website: 'https://www.roseraam.ca/',
    address: { en: '500 Wyandotte St. E, Windsor.' },
    telephone: [{ number: '519-254-3300' }],
  },
  {
    name: { en: 'Roseville RAAM Clinic (Roseville Gardens)' },
    description: '24/7 emergency line for urgent care 519-257-0981',
    lat: 42.3100846,
    lng: -82.94419,
    hours: {
      en: 'Walk-in and scheduled appointments: Mon 8:30am-4:30pm, Thurs 8:30am-12noon. Virtual care available same times.',
    },
    website: 'https://www.roseraam.ca/',
    address: { en: '2525 Roseville Garden Dr. (Suite 100), Windsor' },
    telephone: [{ number: '519-988-1020' }],
  },
  {
    name: { en: 'Sault area Hospital RAAM – Sault Ste Marie' },
    lat: 46.5083752,
    lng: -84.3273701,
    hours: {
      en: 'Walk-in, virtual, and scheduled appointments: Mon 8am-6pm; Tues and Wed 8am-7pm; Thurs and Fri 8am-4pm.',
    },
    website:
      'https://sah.on.ca/programs-services/mental-health-addictions/addictions-services/',
    address: { en: '123 East St, Sault Ste. Marie.' },
    telephone: [{ number: '705-759-3434', extension: '7200' }],
  },
  {
    name: { en: 'Scarborough Health Network RAAM Clinic' },
    lat: 43.7561929,
    lng: -79.2469386,
    hours: {
      en: 'Scheduled in-person and virtual appointments only: Mon, Tues, Thurs, Fri 9:30am-12:30pm, RN/Counsellor appointments: Mon-Fri 8:30-4:30.',
    },
    website: 'https://www.shn.ca/mental-health/addictions-program/',
    address: {
      en: '3050 Lawrence Ave. E, Crockford Pavilion, Ground Floor, Scarborough M1P 2V5',
    },
    telephone: [{ number: '416-431-8269' }],
  },
  {
    name: { en: 'Smooth Rock Falls RAAM Clinic' },
    lat: 49.2746248,
    lng: -81.6118906,
    hours: {
      en: 'Drop-in (in person and virtal) and scheduled appointments: Thurs 1:00-3:30pm.',
    },
    website: 'https://www.addictionservicestoxicomanie.com/',
    address: { en: '107 Kelly Road, Smooth Rock Falls, ON P0L 2B0' },
    telephone: [{ number: '705-272-3059' }],
  },
  {
    name: { en: 'St. Joseph’s Health Centre – Unity Health' },
    lat: 43.63982695108545,
    lng: -79.44980113155394,
    hours: {
      en: 'Open Tuesdays and Thursdays 10:00–11:30am and Wednesdays 1:00–4:00pm.',
    },
    website:
      'https://unityhealth.to/areas-of-care/programs-and-clinics/mental-health-and-addictions/#st-josephs-health-centre-addictions-services-0',
    address: {
      en: 'Family Medicine Centre, East wing, 30 The Queensway, Toronto, ON M6R 1B5',
    },
    telephone: [{ number: '416-530-6486', extension: '3969' }],
  },
  {
    name: { en: 'St. Michael’s Hospital – Rapid Access Clinic (RAC)' },
    description:
      'Virtual care through Coordinated Access to Addictions Services (CAAS) central number: 1-855-505-5045',
    lat: 43.6537678,
    lng: -79.3783212,
    hours: { en: 'Walk-in and scheduled appointments: Mon-Fri 9am-12pm. ' },
    website:
      'https://unityhealth.to/areas-of-care/programs-and-clinics/mental-health-and-addictions/',
    address: {
      en: '30 Bond St., 17th Floor, Cardinal Carter South Wing, Toronto.',
    },
    telephone: [{ number: '416-864-3082' }],
  },
  {
    name: { en: 'Street Health Centre, Kingston Community Health Centre' },
    lat: 44.2337617,
    lng: -76.4831573,
    hours: { en: 'Walk-in and scheduled appointments Mon-Fri 9am-4pm.' },
    website: 'https://kchc.ca/barrack-street/methadone/',
    address: { en: '115 Barrack St, Kingston.' },
    telephone: [{ number: '613-549-1440' }],
  },
  {
    name: { en: 'Sunnybrook Health Sciences Centre' },
    lat: 43.72122161397529,
    lng: -79.37645549893796,
    hours: {
      en: 'Drop-in: Mon 5-7pm (at Vibrant Healthcare Alliance, The Anne Johnston Health Station, 2398 Yonge St.), Wed 1-4pm, Fri 9am-12pm. Booked appointments: Mon 5-7pm, Tues, Thurs 9am-5pm, Wed 1-4pm, Fri 9am-12pm.',
    },
    website: 'https://sunnybrook.ca/content/?page=bsp-rapid-addiction-medicine',
    address: { en: '2075 Bayview Ave., A-wing, Room A146, Toronto, M4N 3M5' },
    telephone: [{ number: '416-480-6736' }],
  },
  {
    name: { en: 'Thunder Bay RAAM – Norwest Community Health Centres site' },
    lat: 48.3912114,
    lng: -89.2374571,
    hours: {
      en: 'Walk-in, scheduled and virtual appointments available during clinic hours: Mon-Thurs 2:00pm-5:00pm.',
    },
    // website: 'https://www.norwestchc.org/locations/thunder-bay/programs/raam-clinic', // 404
    website: 'https://norwestchc.org/programs-services/raam/', // Might be the intended page?
    address: { en: '525 Simpson St, Thunder Bay.' },
    telephone: [{ number: '807-626-8478' }],
  },
  {
    name: { en: 'Timiskaming Shores RAAM Clinic' },
    lat: 47.5082172,
    lng: -79.6705105,
    hours: { en: 'Scheduled appointments: Tues 9-11am.' },
    website: 'https://cmhact.ca/',
    address: { en: '20 May Street South, New Liskeard, ON' },
    telephone: [{ number: '705-647-4444' }],
  },
  {
    name: { en: 'UHN RAAM Clinic, Toronto Western Hospital' },
    lat: 43.6534088,
    lng: -79.405247,
    hours: {
      en: 'Walk-in and drop-in virtual care: Mon-Wed 9:00-11:00am, Thurs and Fri 1:30-3:30pm.',
    },
    website:
      'https://www.uhn.ca/MentalHealth/Clinics/Rapid_Access_Addiction_Medicine/Pages/default.aspx',
    address: {
      en: '399 Bathurst St., 1st Floor, West Wing (WW 1-414), Toronto.',
    },
    telephone: [{ number: '416-726-5052' }],
  },
  {
    name: { en: 'Wawa Family Health Team' },
    lat: 48.0008679,
    lng: -84.772074,
    hours: {
      en: 'Walk-in, virtual, and scheduled appointments: Tues, Thurs 8:30-10:30am.',
    },
    website: 'http://www.wawafamilyhealthteam.com/', // https not available
    address: { en: '17 Government Rd. (Wawa Family Health Team clinic), Wawa' },
    telephone: [{ number: '705-856-1313' }],
  },
];
