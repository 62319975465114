import { Grid } from '@mui/material';
import _ from 'lodash';
import { memo } from 'react';
import { IPhoneNumber } from '../../../../contexts/DataContext';
import { formatPhone } from '../../../../utils/formatPhone';
import ClinicLinkDetails from './ClinicLinkDetails';

interface ClinicPhoneDetailsFactoryProps {
  details: IPhoneNumber[];
}

export const ClinicPhoneDetailsFactory = memo(
  ({ details }: ClinicPhoneDetailsFactoryProps) => {
    return (
      <Grid container rowSpacing={0.75}>
        {_.map(details, (phone, index) => (
          <Grid item xs={12} key={index}>
            <ClinicLinkDetails
              href={`tel:${phone.number}${
                phone.extension ? `;${phone.extension}` : ''
              }`}
              details={formatPhone(phone)}
              tooltipI18n="find_clinic.tooltip.phone_button"
              underline="none"
            />
          </Grid>
        ))}
      </Grid>
    );
  }
);
ClinicPhoneDetailsFactory.displayName = 'ClinicPhoneDetailsFactory';

export default ClinicPhoneDetailsFactory;
