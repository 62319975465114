import { Instance } from 'hive-client-utils';
import { useConnection, useInstancesByClassName } from 'hive-react-utils';
import _ from 'lodash';
import { PropsWithChildren, createContext, useEffect, useMemo } from 'react';
import { ClinicLocation, OFDClinic, WeeklyHours } from '../utils';
import { generateCheckinHours } from '../utils/generateCheckInHours';

export type IPhoneNumber = {
  number: string;
  extension?: string;
};

export type IDirtyText = {
  en: string;
  fr?: string;
};

export type IAltService = {
  name: IDirtyText;
  phone?: IPhoneNumber | {};
  website: string;
};

type IDFD = {
  enabled: boolean;
  orgId: string;
  name: string;
  url: string;
  hours: WeeklyHours;
  breakHours: WeeklyHours;

  locationIds: string[];

  adminContactName: string;
  adminAddress: string;
  adminEmail: string;
  adminPhone: IPhoneNumber[];
  clinicName: IDirtyText;

  enableAltServices: boolean;
  altServices: IAltService[];
};

type IDFDInstance = Instance & { properties: IDFD };

type ILatLng = {
  lat: number;
  lng: number;
};

type ILocation = {
  locationId: string;
  orgId: string;
  raamId: string;

  name: IDirtyText;
  address: IDirtyText;
  coords: ILatLng;
  website: string;
  phone: IPhoneNumber;

  openingHours: WeeklyHours;
  breakHours: WeeklyHours;
};

type ILocationInstance = Instance & {
  properties: ILocation;
};

export interface IDataContext {
  connection?: any;
  dfd: IDFD[];
  locations: ILocation[];
  clinics: OFDClinic[];
}

const DataContext = createContext<IDataContext>({
  dfd: [],
  locations: [],
  clinics: [],
});

export default DataContext;

export function DataContextProvider({
  children,
}: PropsWithChildren<{}>): JSX.Element {
  const connection = useConnection();

  const dfdInstances = useInstancesByClassName<IDFDInstance>('clinic', 'dfd');
  const dfd = useMemo(() => _.map(dfdInstances, 'properties'), [dfdInstances]);

  const locationInstances = useInstancesByClassName<ILocationInstance>(
    'location',
    'location'
  );
  const locations = useMemo(
    () =>
      _.map(locationInstances, (i) =>
        _.assignIn({}, i.properties, { locationId: i.id })
      ),
    [locationInstances]
  );

  const locationsByID = useMemo(
    () => _.mapKeys(locations, (value) => value.locationId),
    [locations]
  );

  const clinics = useMemo(() => {
    return dfd.map(
      (clinic) =>
        ({
          name: clinic.clinicName,
          dfdLink: clinic.url,
          altServices: clinic.enableAltServices ? clinic.altServices : [],
          locations: clinic.locationIds
            .map((id) => {
              const loc = locationsByID[id] as
                | (typeof locationsByID)['id']
                | undefined;
              return (
                loc &&
                ({
                  name: loc.name,
                  website: loc.website,
                  hours: generateCheckinHours(clinic.hours, clinic.breakHours),
                  walkInHours: generateCheckinHours(
                    loc.openingHours,
                    loc.breakHours
                  ),
                  address: loc.address,
                  telephone: [loc.phone],
                  dfdHours: clinic.hours,
                  breakHours: clinic.breakHours,
                  lat: loc.coords.lat,
                  lng: loc.coords.lng,
                } satisfies ClinicLocation)
              );
            })
            .filter((value) => !!value) as ClinicLocation[],
        } satisfies OFDClinic)
    );
  }, [dfd, locationsByID]);

  const value = useMemo(
    () =>
      ({
        connection,
        dfd,
        locations,
        clinics,
      } satisfies IDataContext),
    [connection, dfd, locations, clinics]
  );

  // This is a keep alive of some sort. Whenever a user is deleted (or token revoked, etc), any calls to Hive or the
  // web socket should fail.
  // Since the app is reactive, it might not be making any calls.
  //
  // Anothing thing that should happen is that the web socket should die, then fail when trying to reconnect. The
  // Connection service should be getting the 'reactions.error` event and disconnect on 401/403, but that doesn't
  // seem to happen...
  //
  // For the moment, we will be making a call to Hive every 30s, which will trigger the 'shell.error' event, which will
  // make the ConnectionService handle the disconnection. We will then end up on the login screen.
  useEffect(() => {
    if (!connection || !connection.bee) {
      return;
    }

    const interval = setInterval(() => {
      // We don't care about the result nor exception. Will be handled in ConnectionService.
      connection.bee.auth.hasRole('everyone').catch(() => {});
    }, 30000);

    return () => clearInterval(interval);
  }, [connection]);

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}
