import App from './App';
import './index.css';
// The following just need to be imported to start things
import { createRoot } from 'react-dom/client';
import './services/LocalizationService';
import { LoginService } from './services/LoginService';

// Use different keys for the clinician in order to share the browser
// for development
const KEY_TOKEN = 'hive-onefrontdoor-token';
const KEY_TOKEN_EXPIRATION = 'hive-onefrontdoor-token-expiration';
const KEY_USERNAME = 'hive-onefrontdoor-username';

LoginService.init({
  appName: 'raam-onefrontdoor',
  keyToken: KEY_TOKEN,
  keyTokenExpiration: KEY_TOKEN_EXPIRATION,
  keyUsername: KEY_USERNAME,
}).then(() => {
  const container = document.getElementById('root') as Element;
  const root = createRoot(container);
  root.render(<App />);
});
