import {
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getCoordinatesFromPostalCode } from '../../services/geolocationService';
import {
  ClinicDataType,
  NON_DFD_CLINICS,
  generateDFDClinic,
  haversineDistance,
} from '../../utils';
import DataContext from '../DataContext';
import FindClinicContext from './FindClinicContext';

interface FindClinicContextProviderProps {
  children: ReactNode;
}

const SHOW_STATICS_CLINICS =
  (process.env.REACT_APP_SHOW_STATIC_CLINICS ||
    (window as any)._env_.REACT_APP_SHOW_STATIC_CLINICS) === 'true';

const MAYBE_NON_DFD_CLINICS = SHOW_STATICS_CLINICS ? NON_DFD_CLINICS : [];

const clinicDistanceComparator =
  (coordinates: google.maps.LatLng | google.maps.LatLngLiteral) =>
  (a: ClinicDataType, b: ClinicDataType) => {
    // Returns how much further a is than b
    return (
      haversineDistance(coordinates, a) - haversineDistance(coordinates, b)
    );
  };

export const FindClinicContextProvider = ({
  children,
}: FindClinicContextProviderProps) => {
  const [coordinates, originalSetCoordinates] = useState<
    google.maps.LatLng | google.maps.LatLngLiteral | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clinicData, setClinicData] = useState(MAYBE_NON_DFD_CLINICS);
  const { clinics } = useContext(DataContext);

  useEffect(() => {
    const dfdClinics = clinics.flatMap((clinic) =>
      clinic.locations.map((loc) => generateDFDClinic(clinic, loc))
    );
    const allClinics = [...dfdClinics, ...MAYBE_NON_DFD_CLINICS];
    if (coordinates) {
      allClinics.sort(clinicDistanceComparator(coordinates));
    }
    setClinicData(allClinics);
  }, [clinics, coordinates]);

  const getClinicDistance = useCallback(
    (clinic?: ClinicDataType) => {
      if (coordinates && clinic) {
        return haversineDistance(coordinates, clinic);
      } else {
        return 0;
      }
    },
    [coordinates]
  );

  const setCoordinates = useCallback<
    Dispatch<google.maps.LatLng | google.maps.LatLngLiteral | null>
  >((value) => {
    if (value) {
      setClinicData(
        [...MAYBE_NON_DFD_CLINICS].sort(clinicDistanceComparator(value))
      );
    }
    originalSetCoordinates(value);
  }, []);

  const setCoordFromPostalCode = useCallback(
    async (postalCode: string) => {
      setIsLoading(true);

      try {
        setCoordinates(await getCoordinatesFromPostalCode(postalCode));
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setCoordinates]
  );

  return (
    <FindClinicContext.Provider
      value={{
        coordinates,
        setCoordinates,
        isLoading,
        setIsLoading,
        setCoordFromPostalCode,
        clinicData,
        getClinicDistance,
      }}
    >
      {children}
    </FindClinicContext.Provider>
  );
};

export default FindClinicContextProvider;
