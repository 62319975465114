import { Phone, Public } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { Fragment, memo } from 'react';
import { RED } from '../../../constants/colors';
import { IAltService } from '../../../contexts/DataContext';
import useDirtyTextReader from '../../../hooks/useDirtyTextReader';
import { useTranslation } from '../../../hooks/useKeyedTranslation';
import IconWrapper from '../../Icons/IconWrapper';
import ClinicLinkDetails from './ClinicDetails/ClinicLinkDetails';
import ClinicPhoneDetails from './ClinicDetails/ClinicPhoneDetails';

interface AltServicesProps {
  services: IAltService[];
}

const AltServices = ({ services }: AltServicesProps) => {
  const { t } = useTranslation();
  const dirtyTextReader = useDirtyTextReader();

  return (
    <Box
      paddingLeft="1.5rem"
      paddingTop="0.5rem"
      display="flex"
      flexDirection="column"
      rowGap="0.5rem"
    >
      <Typography
        color={RED}
        fontSize="0.875rem"
        fontStyle="italic"
        fontWeight={500}
        lineHeight="1.25rem"
        letterSpacing="0.01806rem"
      >
        {t('find_clinic.seeking_support_check_in.closed.warning')}
      </Typography>
      {!!services.length && (
        <>
          <Typography
            fontSize="0.875rem"
            fontStyle="italic"
            fontWeight={400}
            lineHeight="1.25rem"
            letterSpacing="0.01806rem"
          >
            {t('find_clinic.seeking_support_check_in.closed.info')}
          </Typography>
          {services.map((value, index) => (
            <Fragment key={value.name.en}>
              {!!index && <Divider />}
              <Box display="flex" flexDirection="column" rowGap="0.5rem">
                <Box display="flex" columnGap="0.5rem">
                  <IconWrapper icon={Public} />
                  <ClinicLinkDetails
                    details={dirtyTextReader(value.name)}
                    href={value.website}
                  />
                </Box>
                {value.phone &&
                  'number' in value.phone &&
                  value.phone.number && (
                    <Box display="flex" columnGap="0.5rem">
                      <IconWrapper icon={Phone} />
                      <ClinicPhoneDetails details={[value.phone]} />
                    </Box>
                  )}
              </Box>
            </Fragment>
          ))}
        </>
      )}
    </Box>
  );
};

export default memo(AltServices);
