import moment from 'moment';
import { ClinicDataType, DayHours, TimeString, isDFDClinic } from './data';

function timeStringToMoment(time: TimeString) {
  const [hours, minutes] = time.split(':').map(Number);
  return moment().set('hour', hours).set('minute', minutes);
}

function isNowBetween(hours: DayHours) {
  return moment().isBetween(
    timeStringToMoment(hours.openHour),
    timeStringToMoment(hours.closeHour),
    'minute',
    '[)'
  );
}

function isWithinHours(openHours: DayHours) {
  return openHours.open && isNowBetween(openHours);
}

export function isClinicOpen(clinic: ClinicDataType) {
  if (isDFDClinic(clinic)) {
    const day = moment().isoWeekday() % 7;
    return (
      isWithinHours(clinic.dfdHours[day]) &&
      !isWithinHours(clinic.breakHours[day])
    );
  }
  return false;
}
