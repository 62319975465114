function narrowAxis(axis: number | (() => number)) {
  return axis instanceof Function ? axis() : axis;
}

export function narrowToLatLngLiteral(
  point: google.maps.LatLng | google.maps.LatLngLiteral
): google.maps.LatLngLiteral {
  return {
    lat: narrowAxis(point.lat),
    lng: narrowAxis(point.lng),
  };
}
